import {getUnityContext} from './UnityConfig'

const reactUnityManager = 'ReactUnityManager'

// Methods on GameObject reactUnityManager
const methods = {
    setUnitSystem: 'SetUnitSystem',
    setProjectInfo: 'SetProjectInfo',
    setProject: 'SetProject',
    setClientInfo: 'SetClientInfo',
    setSettings: 'SetSettings',
    setBuildingDimensions: 'SetBuildingDimensions',
    setFloorPlanSettings: 'SetFloorPlanSettings',
    setFloors: 'SetFloors',
    setFloor: 'SetFloor',
    deleteOutline: 'DeleteOutline',
    setFloorName: 'SetFloorName',
    setStacking: 'SetStacking',
    setElevations: 'SetElevations',
    setMouldingTypes: 'SetMouldingTypes',
    setMaterials: 'SetMaterials',
    setLookTypes: 'SetLookTypes',
    setFinishTypes: 'SetFinishTypes',
    setColorPalette: 'SetColorPalette',
    setPanelTypes: 'SetPanelTypes',
    setPricing: 'SetPricing',
    setPricingSummary: 'SetPricingSummary',
    setPricingCategories: 'SetPricingCategories',
    modelUpdated: 'ModelUpdated',
    setElevation: 'SetElevation',
    setBestFittingOpening: 'SetBestFittingOpening',
    setCroppedElevationPlan: 'SetCroppedElevationPlan',
    setPanelizationSettings: 'SetPanelizationSettingsInUnity',
    updatePanelizationResultInUnity: 'UpdatePanelizationResultInUnity',
    requestModelUpdate: 'RequestModelUpdate',
    addItemToQueue: 'AddItemToQueue',
    removeItemFromQueue: 'RemoveItemFromQueue',
    setBigBlocksInUnity: 'SetBigBlocksInUnity',
    createExportSheet: 'CreateExportSheet',
    setCommands: 'SetCommands',
}

const unityContext = getUnityContext()

export function setUnitSystemUnity(unitSystem) {
    unityContext.send(reactUnityManager, methods.setUnitSystem, unitSystem)
}

export function setProjectInfoUnity(projectInfo) {
    const projectInfoJson = JSON.stringify(projectInfo)
    unityContext.send(reactUnityManager, methods.setProjectInfo, projectInfoJson)
}
export function setProjectUnity(project) {
    const projectJson = JSON.stringify(project)
    unityContext.send(reactUnityManager, methods.setProject, projectJson)
}

export function setClientUnity(clientInfo) {
    const clientInfoJson = JSON.stringify(clientInfo)
    unityContext.send(reactUnityManager, methods.setClientInfo, clientInfoJson)
}

export function setSettingsUnity(settings) {
    const settingsJson = JSON.stringify(settings)
    unityContext?.send(reactUnityManager, methods.setSettings, settingsJson)
}

export function setBuildingDimensionsUnity(buildingDimensions) {
    const buildingDimensionsJson = JSON.stringify(buildingDimensions)
    unityContext?.send(reactUnityManager, methods.setBuildingDimensions, buildingDimensionsJson)
}

export function setFloorPlanSettings(floorPlanSettings) {
    const floorPlanSettingsJson = JSON.stringify(floorPlanSettings)
    unityContext.send(reactUnityManager, methods.setFloorPlanSettings, floorPlanSettingsJson)
}

export function setFloors(floorPlans) {
    const items = {
        items: floorPlans
    }
    const floorPlansJson = JSON.stringify(items)
    unityContext.send(reactUnityManager, methods.setFloors, floorPlansJson)
}

export function setMouldingTypes(mouldingTypes) {
    const items = {
        items: mouldingTypes
    }
    const mouldingTypesJson = JSON.stringify(items)

    unityContext.send(reactUnityManager, methods.setMouldingTypes, mouldingTypesJson)
}

export function setBigBlocksInUnity(bigBlocks) {
    const items = {
        items: bigBlocks
    }
    const blocksJson = JSON.stringify(items)
    
    unityContext.send(reactUnityManager, methods.setBigBlocksInUnity, blocksJson)
}

export function setMaterials(materials) {
    const items = {
        items: materials
    }
    const materialJson = JSON.stringify(items)
    unityContext.send(reactUnityManager, methods.setMaterials, materialJson)
}

export function setFloor(floor) {
    const floorJson = JSON.stringify(floor)
    unityContext.send(reactUnityManager, methods.setFloor, floorJson)
}

export function setDeleteOutlineInUnity(floorId, outlineId) {
    const items = {
        floorId: floorId,
        outlineId: outlineId
    }
    const deleteOutlineJson = JSON.stringify(items)
    unityContext.send(reactUnityManager, methods.deleteOutline, deleteOutlineJson)
    console.log(deleteOutlineJson)
}

export function setFloorName(floor) {
    const floorJson = JSON.stringify(floor)
    unityContext.send(reactUnityManager, methods.setFloorName, floorJson)
}

export function setPanelizationSettings(panelizationSettings) {
    const panelizationSettingsJson = JSON.stringify(panelizationSettings)
    unityContext.send(reactUnityManager, methods.setPanelizationSettings, panelizationSettingsJson)
}

export function setPanelizationResult(panelizationResult) {
    const panelizationResultJson = JSON.stringify(panelizationResult)
    unityContext.send(reactUnityManager, methods.updatePanelizationResultInUnity, panelizationResultJson)
}

export function setElevations(elevations) {
    const items = {
        items: elevations
    }
    const elevationsJson = JSON.stringify(items)
    unityContext.send(reactUnityManager, methods.setElevations, elevationsJson)
}

export function setElevation(elevation) {
    const elevationJson = JSON.stringify(elevation)
    unityContext.send(reactUnityManager, methods.setElevation, elevationJson)
}

export function setBestFittingOpening(bestFittingOpening) {
    const bestFittingOpeningJson = JSON.stringify(bestFittingOpening)
    unityContext.send(reactUnityManager, methods.setBestFittingOpening, bestFittingOpeningJson)
}

export function setLookTypes(lookTypes) {
    const items = {
        items: lookTypes
    }
    const lookTypesJson = JSON.stringify(items)
    unityContext.send(reactUnityManager, methods.setLookTypes, lookTypesJson)
}

export function setFinishTypes(finishTypes) {
    const items = {
        items: finishTypes
    }
    const finishTypesJson = JSON.stringify(items)
    unityContext.send(reactUnityManager, methods.setFinishTypes, finishTypesJson)
}

export function setColorPalette(colorPalette) {
    const items = {
        items: colorPalette
    }
    const colorsJson = JSON.stringify(items)
    unityContext.send(reactUnityManager, methods.setColorPalette, colorsJson)
}

export function setPanelTypes(panelTypes) {
    const items = {
        items: panelTypes
    }
    const panelTypeJson = JSON.stringify(items)
    unityContext.send(reactUnityManager, methods.setPanelTypes, panelTypeJson)
}

export function setPricing(pricing) {
    const pricingJson = JSON.stringify(pricing)
    //console.log(pricingJson)
    unityContext.send(reactUnityManager, methods.setPricing, pricingJson)
}

export function setPricingSummary(pricingSummary) {
    const pricingSummaryJson = JSON.stringify(pricingSummary)
    //console.log(pricingSummaryJson)
    unityContext.send(reactUnityManager, methods.setPricingSummary, pricingSummaryJson)
}

export function setPricingCategories(pricingCategoriesJson) {
    const pricingCategories = JSON.stringify(pricingCategoriesJson)
    unityContext.send(reactUnityManager, methods.setPricingCategories, pricingCategories)
}

export function modelUpdated(url) {
    console.log("Path for updated model: " + url)
    unityContext.send(reactUnityManager, methods.modelUpdated, url)
}

export function addItemToLoadingQueue(item) {
    unityContext.send(reactUnityManager, methods.addItemToQueue, item)
}

export function removeItemFromQueue(item) {
    console.log("removing")
    unityContext.send(reactUnityManager, methods.removeItemFromQueue, item)
}

export function createExportSheet() {
    console.log("send request to unity")
    unityContext.send(reactUnityManager, methods.createExportSheet)
}

export function setCroppedElevationPlan(croppedPlan) {
    const croppedElevationPlanJson = JSON.stringify(croppedPlan)
    unityContext.send(reactUnityManager, methods.setCroppedElevationPlan, croppedElevationPlanJson)
}


export function requestModelUpdate() {
    unityContext.send(reactUnityManager, methods.requestModelUpdate)
}

export function setCommands(commands) {
    const commandsJson = JSON.stringify(commands)
    unityContext.send(reactUnityManager, methods.setCommands, commandsJson)
}