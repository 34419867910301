import React from 'react'
import {getAreaConverted, selectText} from './Helpers'
import './export.css'


export const ExportPanelTypes = (props) => {

    const exportData = props.data
    const panelTypes = props.panelTypes
    const unitSystem = props.unitSystem
    const pricingLevel = props.pricingLevel
    const materials = props.materials

    function getPanelName(panelId, panelTypes) {

        if (pricingLevel === "LEVEL_4") {
            const mat = materials.find(material => material.panelTypeId === panelId)
            const pid = mat.panelTypeIdLevel4
            return panelTypes.filter(panel => panel.id === pid)[0].name

        }
        else
            return panelTypes.filter(panel => panel.id === (panelId))[0].name
    }

    return (
        <div>
            <table id="panelTable" className={"export-table"}>
                <tbody>
                <tr>
                    <td colSpan="2" className="infoblockTitle copytable"  onMouseDown={() => selectText("panelTable")}>
                        Panel Types
                    </td>
                </tr>
                {
                    exportData.map(
                        function(panel, i) {
                            if (panel.panelArea > 0)
                            return (
                                <tr key={i}>
                                    <td className="tlabel">{getPanelName(panel.panelId, panelTypes)}</td>
                                    <td className="tcontent">{getAreaConverted(panel.panelArea, unitSystem)}</td>
                                </tr>
                            )
                            else return (null)
                        }
                    )
                }
                </tbody>
            </table>
        </div>
    );
};