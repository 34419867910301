
import AirMoistureBarrierPNG from "../../panelConfiguration/assets/png/air&moistureBarrier.png";
import InsulationPNG from "../../panelConfiguration/assets/png/insulation.png";
import DrainagePNG from "../../panelConfiguration/assets/png/drainage.png";
import BasecoatPNG from "../../panelConfiguration/assets/png/basecoat.png";
import FramingPNG from "../../panelConfiguration/assets/png/framing.png";
import SheathingPNG from "../../panelConfiguration/assets/png/sheathing.png";

import NoPreviewAvailable from "../../panelConfiguration/assets/png/no_preview_available.png";

export function getSrcByComponentGroup(componentGroup) {
    switch (componentGroup?.name.toLowerCase()) {
        case 'air & moisture barrier':
            return AirMoistureBarrierPNG
        case 'insulation':
            return InsulationPNG
        case 'drainage':
            return DrainagePNG
        case 'basecoat':
            return BasecoatPNG
        case 'sheathing':
            return SheathingPNG
        case 'framing':
            return FramingPNG
        default:
            return NoPreviewAvailable
    }
}