import {makeStyles} from "@mui/styles";
import {Tooltip, Typography} from "@mui/material";
import React from "react";
import StoLengthInput from "./StoLengthInput";
import {
    PRICING_LEVEL,
    updateSingleLevel2PricingItem,
    updateSingleLevel3PricingItem
} from "../../pricingLevel/pricingSlice";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import StoPricePerUnitInput from "./StoPricePerUnitInput";
import infoIcon from "../../utils/icons/info.svg"


const useStyle = makeStyles(() => ({
    root: {
        display: 'grid',
        //paddingBottom: '2rem',
        "&.MuiAccordion-root:before": {
            backgroundColor: "white"
        },
        //width: '80rem',


    },
    box: {
        display: 'grid',

        gridTemplateColumns: '1fr 1fr 1fr',
        justifyContent: 'center',
        "&.MuiButtonBase-root-MuiAccordionSummary-root": {
            alignItems: 'flex'
        }

    },
    innerBox: {
        display: 'flex',
        alignItems: 'center',
        height: '55px',
        paddingLeft: '1.5em',
        justifyContent: 'flex-start'
    },
    focusVisible: {
        backgroundColor: 'white!important',
    },
    backup: {
        paddingTop: '0.5rem',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        height: '50px',
        paddingBottom: '1rem'
        // paddingLeft: '7px'
    },
    level3: {},
    pricingCategoryFont: {
        fontFamily: 'FrutigerNext-Bd',
        fontSize: '16px !important',
        display: 'flex',
        alignItems: 'center',

    },
    tooltipIcon: {
        marginLeft: '0.5rem',
    },
    pricingItemFont: {

        fontSize: '16px !important',
        fontFamily: 'FrutigerNext !important',
        fontWeight: '400 !important',
        display: 'flex',
        alignItems: 'center',

    },
    placeHolder: {
        width: '14rem',
        height: '1rem',
    },
    pricingInput: {
        width: '13rem!important',
    },
    pricingLabel: {
        width: '300px',
    }


}));

function StoPricingItem({category: item, key, backgroundColor, hideLabor, tooltips, ...props}) {
    const classes = useStyle({...props.theme});
    const pricingLevel = useSelector(state => state.settings.settings.pricingLevel)
    const dispatch = useDispatch()
    const {projectId} = useParams();

    const handlePricingItemChange = (pricingItem, materialCost, laborCost, returnDepthEdge, returnDepthWindow) => {
        //create copy of pricingItem
        const item = {...pricingItem}
        item.materialCost = parseFloat(materialCost)
        item.laborCost = parseFloat(laborCost)
        item.returnDepthEdge = parseFloat(returnDepthEdge)
        item.returnDepthWindow = parseFloat(returnDepthWindow)




            if (pricingLevel === PRICING_LEVEL.LEVEL_3) {
                dispatch(updateSingleLevel3PricingItem({
                    projectId: projectId,
                    pricingItem: item
                }))
            } else {
                dispatch(updateSingleLevel2PricingItem({
                    projectId: projectId,
                    pricingItem: item
                }))
            }

    }

    return (
        <div className={classes.innerBox} style={{backgroundColor: backgroundColor}} key={key}>
            {item?.materialCost >= 0 ?
                <>
                    <Typography className={`${classes.pricingItemFont} ${classes.pricingLabel}`}>{item.name}
                        {tooltips && tooltips.map((tooltip, i) => {
                            if (tooltip.id === item.id) {

                                const toolTipContent = [];

                                if (tooltip.referenceUnit) {
                                    toolTipContent.length > 0 && toolTipContent.push(<br/>);
                                    toolTipContent.push("Reference unit: " + tooltip.referenceUnit);
                                }
                                if (tooltip.text) {
                                    toolTipContent.length > 0 && toolTipContent.push(<br/>);
                                    toolTipContent.push(tooltip.text);
                                }

                                return (
                                    <span key={i}>
                                    <Tooltip title={<><h1>{tooltip.title}</h1><span
                                        className={"tooltipText"}>{toolTipContent}</span></>} arrow={true}
                                             placement={"bottom-start"}>
                                        <img src={infoIcon} className={classes.tooltipIcon} alt={tooltip.title}/>
                                    </Tooltip></span>
                                )
                            }
                            return null
                        })}
                    </Typography>
                    <StoPricePerUnitInput title={''} value={item.materialCost} padding={'5px'}
                                          className={classes.pricingInput}
                                          alignment={'right'}
                                          width={'7rem'} endText={'$'} maxLength={7}
                                          unit={item.reference}
                                          onBlur={(event) => handlePricingItemChange(item, event, -1, -1, -1)}
                    />
                    {pricingLevel === PRICING_LEVEL.LEVEL_3 && !hideLabor && item.id !== "window" && item.id !== "window_piece" &&
                        <StoPricePerUnitInput title={''} value={item.laborCost} padding={'5px'}
                                              className={classes.pricingInput}
                                              alignment={'right'}
                                              width={'7rem'} endText={'$'} maxLength={7}
                                              unit={item.reference}
                                              onBlur={(event) => handlePricingItemChange(item, -1, event, -1, -1)}>

                        </StoPricePerUnitInput>
                    }

                </> :
                <>
                    <Typography className={`${classes.pricingItemFont} ${classes.pricingLabel}`}>General</Typography>
                    <div className={classes.placeHolder}/>

                    {pricingLevel === PRICING_LEVEL.LEVEL_3 &&
                        <div className={classes.placeHolder}/>
                    }
                </>
            }
            {item?.returnDepth ? <>
                    <StoLengthInput title={''} value={item.returnDepthEdge} padding={'5px'} alignment={'right'}
                                    className={classes.pricingInput}
                                    width={'7rem'} endText={'$'} maxLength={7}
                    onBlur={(event) => handlePricingItemChange(item, -1, -1, event, -1)}/>
                    <StoLengthInput title={''} value={item.returnDepthWindow} padding={'5px'} alignment={'right'}
                                    className={classes.pricingInput}
                                    width={'7rem'} endText={'$'} maxLength={7}
                                    onBlur={(event) => handlePricingItemChange(item, -1, -1, -1, event)}/>
                </>
                : <></>}

        </div>


    );
}

export default StoPricingItem;