import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {getPricing, PRICING_LEVEL, updateGeneralPanelConfigurations} from "../pricingSlice";
import PricingLevel1 from "./pricingLevel1";
import {useDispatch, useSelector} from "react-redux";
import PricingSidebar from "./PricingSidebar";
import PricingLevel2And3 from "./pricingLevel2_3";
import PricingLevel4 from "./pricingLevel4";
import {level_4_pricing_fake} from "./level_4_pricing_fake";
import {useParams} from "react-router-dom";
import PricingLevel4General from "./pricingLevel4General";

const useStyle = makeStyles(() => ({
    levelButton: {
        width: '125px',
        height: "inherit",
        borderRadius: '2px'
    },
    buttonGroup: {
        height: '25px',
        borderRadius: '2px',
        marginLeft: '10rem',
        outline: '1px solid lightgrey',
        outlineOffset: '8px',
    },
    pricingSelector: {
        float: 'left',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '280px',
        background: 'white',
        boxSizing: 'border-box',
        paddingTop: '1rem',
        borderRight: '1px solid lightgrey',
        bottom: '0',
        top: '0',
        position: 'absolute',

    },
    pricingRoot: {
        boxSizing: 'border-box',
        display: 'flex',
        height: '100%',
    },
    pricingCategoryFont: {
        fontFamily: 'FrutigerNext-Bd',
        fontSize: '16px !important',
        margin: '3rem 0'
    },
    radioButton: {
        margin: '1rem 0',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        width: '100%',
        fontFamily: 'FrutigerNext',
        fontSize: '16px',
    },
    radioGroup: {
        width: 'auto',
        paddingLeft: '4rem',
        borderBottom: '1px solid lightgrey',
        paddingBottom: '1rem',
    },
    contentArea: {
        overflowY: "auto",
        width: "calc(100% - 30rem)",
        boxSizing: "border-box",

        paddingTop: '3.6rem',
        paddingLeft: '3.2rem',
        paddingRight: '3.2rem',
    },
    formControl: {
        width: '100%',

    },

}))

function PricingLevel(props) {
    const classes = useStyle(props.theme);
    const pricingLevel = useSelector(state => state.settings.settings.pricingLevel)
    const pricing = useSelector(state => state.pricing)
    const panelTypes = useSelector(state => state.panelTypes.panelTypes)

    const [scrollToItem, setScrollToItem] = useState(null);
    const [scrollToItemLevel4, setScrollToItemLevel4] = useState({});

    const [activePanelType, setActivePanelType] = useState(null);

    const dispatch = useDispatch()
    const {projectId} = useParams()

    const [pricingLevel2Categories, setPricingLevel2Categories] = useState([])
    const [pricingLevel3Categories, setPricingLevel3Categories] = useState([])
    const [pricingCategories, setPricingCategories] = useState([]);

    const [panelType,] = useState('StoPanel Classic Next Ci')
    const currentPanelConfiguration = pricing && pricing.panelConfigurations
        ? pricing?.panelConfigurations?.find(panelConfiguration => panelConfiguration?.name?.toLowerCase().replace(" ", "") ===
            panelType?.toLowerCase().replace(" ", "") && panelTypes.find(pT => pT.id === panelConfiguration?.panelTypeId)?.active)
        : null

    useEffect(() => {
        dispatch(getPricing({projectId: projectId}))
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (pricing.singleUpdate && pricingLevel2Categories?.length !== 0 && pricingLevel3Categories?.length !== 0) {
            return
        }

        setPricingLevel2Categories(pricing.pricingCategoriesLevel2)
        setPricingLevel3Categories(pricing.pricingCategoriesLevel3)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pricing, pricingLevel2Categories?.length, pricingLevel3Categories?.length])

    useEffect(() => {
        setActivePanelType({name: 'General Configurations'})
    }, [pricingLevel, setActivePanelType]);

    useEffect(() => {
        setPricingCategories(pricingLevel === PRICING_LEVEL.LEVEL_2 ? pricingLevel2Categories : pricingLevel3Categories);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pricingLevel, pricing, pricingLevel2Categories, pricingLevel3Categories]);

    function updatePanelConfiguration(componentGroupDTO) {
        dispatch(updateGeneralPanelConfigurations({
            projectId: projectId, componentGroupDTO: componentGroupDTO
        }))
    }

    function getContent() {
        switch (pricingLevel) {
            case PRICING_LEVEL.LEVEL_1:
                return <PricingLevel1/>
            case PRICING_LEVEL.LEVEL_2:
            case PRICING_LEVEL.LEVEL_3:
                return <PricingLevel2And3 scrollToItem={scrollToItem} pricingLevel={pricingLevel}
                                          pricingCategories={pricingCategories}/>
            case PRICING_LEVEL.LEVEL_4:
                return activePanelType?.name !== 'General Configurations'
                    ? <PricingLevel4 scrollToItem={scrollToItemLevel4}
                                     level_4_pricing_fake={level_4_pricing_fake}
                                     currentPanelConfiguration={currentPanelConfiguration}
                                     updatePanelConfiguration={updatePanelConfiguration}/>
                    : <PricingLevel4General/>

            default:
                return null;
        }
    }

    const content = getContent();

    const isLevel4 = pricingLevel === PRICING_LEVEL.LEVEL_4

    const paddingLeft = isLevel4 ? '0' : '3.2rem'
    const paddingTop = isLevel4 ? '0' : '3.6rem'
    const paddingRight = isLevel4 ? '0' : '3.2rem'

    return (
        <div className={classes.pricingRoot}>
            <PricingSidebar setScrollToItem={setScrollToItem}
                            setScrollToItemLevel4={setScrollToItemLevel4}
                            pricingCategories={pricingCategories}
                            activePanelType={activePanelType}
                            setActivePanelType={(panelType) => setActivePanelType(panelType)}/>
            <div className={classes.contentArea} style={{
                background: 'white',
                paddingLeft: paddingLeft,
                paddingTop: paddingTop,
                paddingRight: paddingRight
            }}>
                <div style={{display: 'flex', whiteSpace: 'nowrap'}}>
                    {content}
                </div>
            </div>
        </div>
    );
}

export default PricingLevel