import {makeStyles} from "@mui/styles";
import {Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import StoLabelDropdown from "../utils/components/StoLabelDropdown";
import StoInput from "../utils/components/StoInput";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {updateMaterial} from "../material/materialSlice";
import {getToolTipTitle} from "../utils/toolTipUtil";

const useStyle = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '18rem',
        gap: '.8rem',
        paddingTop: '1.6rem',
        paddingBottom: '1.6rem',
        paddingLeft: '3.2rem',
        paddingRight: '3.2rem',
        backgroundColor: '#f2f2f2',
        borderBottom: '0.25px solid #CCCCCC'
    },
    content: {
        width: 'calc(100% - 3.2rem)',
        padding: '1.6rem',
        backgroundColor: 'white',
    },
    materialProperties: {
        display: 'flex',
        flexDirection: 'column',
        width: 'calc(100% - 28.8rem)',
        gap: '.8rem',
        paddingLeft: '4.8rem',
    },

}))

function PanelConfigurationByMaterial({material}) {
    const classes = useStyle()

    const panelTypes = useSelector(state => state.panelTypes.panelTypes)
    const lookTypes = useSelector(state => state.lookTypes.lookTypes)

    const [materialState, setMaterialState] = useState(material)
    const [materialName, setMaterialName] = useState(material ? material.name : '')

    const {projectId} = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        setMaterialState(material)
        setMaterialName(material?.name)
    }, [material])

    const panelTypeNames = panelTypes?.filter(panelType => panelType.active)?.map(panelType => panelType.name)
    const currentPanelType = panelTypes?.find(panelType => panelType.id === materialState?.panelTypeIdLevel4)?.name

    const lookTypeNames = lookTypes?.map(lookType => lookType.name)
    const currentLookType = lookTypes?.find(lookType => lookType.id === materialState?.lookTypeId)?.name

    function handleMaterialNameChanged(materialName) {
        if (materialState.name === materialName) {
            return
        }

        const updatedMaterial = {...materialState, name: materialName}
        setMaterialState(updatedMaterial)
        dispatch(updateMaterial({projectId: projectId, material: updatedMaterial}))
    }

    function handlePanelTypeChanged(panelType) {
        if (currentPanelType === panelType) {
            return
        }

        const updatedMaterial = {...materialState, panelTypeIdLevel4: panelTypes?.find(panel => panel.name === panelType)?.id}
        setMaterialState(updatedMaterial)
        dispatch(updateMaterial({projectId: projectId, material: updatedMaterial}))
    }

    return <div className={classes.container}>
        <Typography variant={'bodyText'}>General Configurations</Typography>
        <div className={classes.content}>
            <div className={classes.materialProperties}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Typography variant={'subtitle1'} style={{width: '26.4rem'}}>Name</Typography>
                    <StoInput value={materialName} style={{width: 'calc(100% - 26.4rem)', height: '3.2rem'}}
                    onBlur={(event) => handleMaterialNameChanged(event.target.value)}/>
                </div>
                <StoLabelDropdown label={'Look Type'} value={currentLookType} options={lookTypeNames}
                                  labelStyle={{width: '26.4rem'}} disabled toolTip={getToolTipTitle('Info', 'Go to Elevation/Material to set another look type')}/>
                <StoLabelDropdown label={'Panel Type'} value={currentPanelType} options={panelTypeNames}
                                  labelStyle={{width: '26.4rem'}} onChange={handlePanelTypeChanged}/>
            </div>
        </div>
    </div>
}

export default PanelConfigurationByMaterial