import StoSidebarItem from "../../scheduling/Settings/StoSidebarItem";
import {Collapse} from "@mui/material";
import React, {Fragment, useState} from "react";

function PricingSidebarNavigation({setScrollToItem, categories}) {
    const [collapseGeneral, setCollapseGeneral] = useState(true);
    const [collapseSpecific, setCollapseSpecific] = useState(true);
    const [activeName, setActiveName] = useState("");

    function toggleCollapseGeneral() {
        setCollapseGeneral(!collapseGeneral);
    }

    function toggleCollapseSpecific() {
        setCollapseSpecific(!collapseSpecific);
    }

    const setSidebarItem = (name, index) => {
        setActiveName(name);
        if (setScrollToItem) {
            setScrollToItem(index);
        }
    };

    return (
        <Fragment>
            <StoSidebarItem name={"General Data"}
                            toggleCollapse={toggleCollapseGeneral}
                            collapse={collapseGeneral}
                            setSidebarItem={toggleCollapseGeneral}/>
            <Collapse in={collapseGeneral}>
                {
                    categories?.map((category, i) => {
                        if (category.id?.startsWith('specific') || category.name === '' || category.name === null) {
                            return null
                        }

                        return (
                            <div key={i}>
                                <StoSidebarItem name={category.name} toggleCollapse={null} activeItem={activeName}
                                                setSidebarItem={() => setSidebarItem(category.name, i)}/>
                            </div>
                        )
                    })
                }
            </Collapse>

            <StoSidebarItem name={"Specific Data"} toggleCollapse={toggleCollapseSpecific} collapse={collapseSpecific}
                            setSidebarItem={toggleCollapseSpecific}/>
            <Collapse in={collapseSpecific}>
                {
                    categories?.map((category, i) => {
                        if (!category.id?.startsWith('specific') || category.name === '' || category.name === null) {
                            return null
                        }

                        return (
                            <div key={i}>
                                <StoSidebarItem name={category.name} toggleCollapse={null} activeItem={activeName}
                                                setSidebarItem={() => setSidebarItem(category.name, i)}/>
                            </div>
                        )
                    })
                }
            </Collapse>
        </Fragment>
    )
}

export default PricingSidebarNavigation
