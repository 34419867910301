export const level_4_pricing_fake = {
    stoPanelBackupPanel: {
        layer: [
            {
                index: 1,
                name: 'Panel Cost',
                materials: [
                    {
                        name: 'Fasteners',
                        value: 'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675432',
                        options: ['Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675432',
                            'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675433',
                            'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675434']
                    },
                    {
                        name: 'Bridging Channels',
                        value: 'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675432',
                        options: ['Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675432',
                            'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675433',
                            'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675434']
                    },
                    {
                        name: 'CFS Track',
                        value: 'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675432',
                        options: ['Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675432',
                            'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675433',
                            'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675434']
                    },
                    {
                        name: 'Bridging Clips',
                        value: 'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675432',
                        options: ['Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675432',
                            'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675433',
                            'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675434']
                    },
                    {
                        name: 'Welding Material',
                        value: 'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675432',
                        options: ['Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675432',
                            'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675433',
                            'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675434']
                    },
                    {
                        name: 'Hoisting Panel Bolts',
                        value: 'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675432',
                        options: ['Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675432',
                            'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675433',
                            'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675434']
                    },
                    {
                        name: 'CFS Stud',
                        value: 'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675432',
                        options: ['Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675432',
                            'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675433',
                            'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675434']
                    }
                ]
            },
            {
                index: 2,
                name: 'Mouldings',
                materials: [
                    {
                        name: 'Fasteners',
                        value: 'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675432',
                        options: ['Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675432',
                            'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675433',
                            'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675434']
                    },
                    {
                        name: 'Bridging Channels',
                        value: 'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675432',
                        options: ['Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675432',
                            'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675433',
                            'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675434']
                    }
                ]
            },
            {
                index: 3,
                name: 'Prefinished',
                materials: [
                    {
                        name: 'Fasteners',
                        value: 'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675432',
                        options: ['Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675432',
                            'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675433',
                            'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675434']
                    },
                    {
                        name: 'Bridging Channels',
                        value: 'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675432',
                        options: ['Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675432',
                            'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675433',
                            'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675434']
                    },
                    {
                        name: 'CFS Track',
                        value: 'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675432',
                        options: ['Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675432',
                            'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675433',
                            'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675434']
                    },
                    {
                        name: 'Bridging Clips',
                        value: 'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675432',
                        options: ['Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675432',
                            'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675433',
                            'Super-Langer-Name fuer Bauteil 023947123478-123412341 BR.:21345675434']
                    }
                ]
            }
        ]
    },

}