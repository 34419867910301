import {makeStyles} from "@mui/styles";
import {Tooltip, Typography} from "@mui/material";
import React from "react";
import StoInput from "../../utils/components/StoInput";
import {useDispatch, useSelector} from "react-redux";
import {refreshPricingSummary, updatePricingSummaryItem} from "../pricingSummarySlice";
import {useParams} from "react-router-dom";
import {updateMouldingType} from "../../mouldingType/mouldingTypeSlice";
import {
    updateLvl1GeneralConfig,
    updateLvl4GeneralConfig, updateSingleLevel2PricingItem,
    updateSingleLevel3PricingItem
} from "../../pricingLevel/pricingSlice";
import infoIcon from "../../utils/icons/info.svg";
import {styled} from "@mui/material/styles";



const useStyle = makeStyles((theme) => ({
    root: {
        display: 'grid',
        //paddingBottom: '2rem',
        "&.MuiAccordion-root:before": {
            backgroundColor: "white"
        },
        //width: '80rem',


    },
    box: {
        display: 'grid',

        gridTemplateColumns: '1fr 1fr 1fr',
        justifyContent: 'center',
        "&.MuiButtonBase-root-MuiAccordionSummary-root": {
            alignItems: 'flex'
        }

    },
    innerBox: {
        display: 'flex',
        alignItems: 'center',
        height: '40px',
        paddingLeft: '1.5em',
        justifyContent: 'flex-start',
        borderTop: '1px solid',
        borderRight: '1px solid',
        borderColor: theme.palette.neutral.primary20,
        borderRightColor: theme.palette.neutral.primary20,
        width: '82rem',
        /*add borderbottom to last element*/
    },
    lastBox: {
        borderColor: theme.palette.neutral.primary20,
        borderBottomColor: theme.palette.neutral.primary20,
        borderTop: '1px solid',
        borderBottom: '1px solid',
    },
    focusVisible: {
        backgroundColor: 'white!important',
    },
    backup: {
        paddingTop: '0.5rem',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        height: '50px',
        paddingBottom: '1rem'
        // paddingLeft: '7px'
    },
    level3: {},

    pricingCategoryFont: {
        fontFamily: 'FrutigerNext-Bd',
        fontSize: '16px !important',
        display: 'flex',
        alignItems: 'center',

    },
    tooltipIcon: {
        marginLeft: '0.5rem',
    },
    pricingItemFont: {

        fontSize: '14px !important',
        fontFamily: 'FrutigerNext !important',
        fontWeight: '400 !important',
        display: 'flex',
        alignItems: 'center',
        color: '#767676 !important',

    },
    pricingItemFontBold: {

        fontSize: '14px !important',
        fontFamily: 'FrutigerNext-Bd !important',
        fontWeight: '400 !important',
        display: 'flex',
        alignItems: 'center',
        color: '#767676 !important',

    },

    placeHolder: {
        width: '14rem',
        height: '1rem',
    },
    pricingInput: {

        textAlign: 'right',
        display: 'inline',
        color: '#767676 !important'
    },
    pricingLabel: {
        width: '300px',
    },
    vspacer: {
        width: '1px',
        height: '100%',
        backgroundColor: theme.palette.neutral.primary20,
        margin: '0 1rem 0 1rem'
    },
    vspacerFat: {
        width: '2px',
        height: '100%',
        backgroundColor: theme.palette.neutral.primary20,
        margin: '0 1rem 0 1rem'
    },
    inlineInput: {
        '& .MuiOutlinedInput-root': {
            borderRadius: "2px",
            backgroundColor: theme.palette.neutral.white,
            fontSize: '13px',
            marginTop: '0 !important',
            width: '8rem',
        },
    }


}));

function PricingSummaryItem({item, key, backgroundColor, unitSystem, lastElement, isResult, ...props}) {
    const classes = useStyle({...props.theme});
    const dispatch = useDispatch()
    const mouldingTypes = useSelector((state) => state.mouldingTypeReducer.mouldingTypes)
    const pricingLevel = useSelector((state) => state.settings.settings.pricingLevel)
    const mouldingType = mouldingTypes.find((moulding) => moulding.id === item.parentId)
    const defaultsLevel1 = useSelector((state) => state.pricing.generalConfigLevel1)
    const defaultsLevel4 = useSelector((state) => state.pricing.generalConfigLevel4)
    const pricingCategoriesLevel2 = useSelector((state) => state.pricing.pricingCategoriesLevel2)
    const pricingCategoriesLevel3 = useSelector((state) => state.pricing.pricingCategoriesLevel3)
    const {projectId} = useParams();
    const pricingFactorLength =  unitSystem === 'METRICAL' ? 1 : 3.28084
    const pricingFactorArea =unitSystem === 'METRICAL' ? 1 : 10.7639

    const CustomTooltip = styled(({className, ...props}) => (
        <Tooltip {...props} classes={{popper: className}}/>
    ))({
        [`& .MuiTooltip-tooltip`]: {
            fontSize: '1.2em', // Schriftgröße anpassen
            padding: '10px 20px', // Padding anpassen
            maxWidth: 'none', // Max-Breite entfernen, um flexibler zu sein
        },
    });

    let componentGroup = ''
    const renderComponent = (component, index) => {
        let groupChanged = false;
        if (component.componentGroup !== componentGroup) {
            componentGroup = component.componentGroup;
            groupChanged = true;
        }
        // create sum of all costs from component.componentGroup
        let sum = 0
        item.components.forEach((component) => {
            if (component.componentGroup === componentGroup) {
                sum += parseFloat(component.cost)
            }
        })
        return (<>
            {groupChanged &&
                <>
                    <tr>
                        <td></td>
                    </tr>
                    <tr>
                        <td colSpan={2}><b>{component.componentGroup}</b></td>
                        <td align={"right"}><b>{sum.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })} $</b></td>
                    </tr>
                </>
            }
            <tr key={index}>
                <td style={{paddingRight: 10 + "px", paddingLeft: 10 + "px"}}>{component.material}</td>
                <td align = {"right"} style={{paddingRight: 10 + "px", paddingLeft: 10 + "px"}}>{renderUnit(component)}</td>
                <td align={"right"}>{parseFloat(component.cost).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })} $</td>
            </tr>
        </>)
    }

    const renderUnit = (component) => {
        if (unitSystem === 'METRICAL') {
            return (
                <>{parseFloat(component.quantity).toFixed(2)} {component.unit}</>
            )
        }
        let impUnit = component.unit
        let impValue = component.quantity
        switch (component.unit) {
            case 'm':
                impUnit = 'ft'
                impValue = component.quantity * 3.28084
                break;
            case 'sqm':
                impUnit = 'sqft'
                impValue = component.quantity * 10.7639
                break;
            case 'cm':
                impUnit = 'in'
                impValue = component.quantity * 0.393701
                break;
            case 'mm':
                impUnit = 'in'
                impValue = component.quantity * 0.0393701
                break;
            default:
                impUnit = component.unit
                impValue = component.quantity
        }
        return (
            <>{parseFloat(impValue).toFixed(2)} {impUnit}</>
        )
    }

    const getComponents = (item) => {
        // create sorted list of item.components, sort after component.sorting
        //convert to json and back to get a copy of the list
        const unsortedList = JSON.parse(JSON.stringify(item.components.filter((component) => component.cost > 0)))
        const list = unsortedList.sort((a, b) => a.sorting - b.sorting)
        console.log(item)

        return (
            <table>
                <tbody>
                {list.map((component, index) => (
                    renderComponent(component, index)
                ))}
                <tr>
                    <td colSpan={3} align={"right"}>
                        <hr></hr>
                        <b>{item.price.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })} $</b>
                    </td>
                </tr>
                </tbody>
            </table>
        );
    };


    const getUnit = (item) => {
        switch (item.unit) {
            case 'FIELDAPPLIED':
            case 'FINISHAREA':
            case 'AREA':
            case 'WINDOWAREA':
                return unitSystem === 'METRICAL' ? 'm²' : 'ft²';
            case 'PERCENTAGE':
                return '%';
            case 'LENGTH':
            case 'MOULDING':
                return unitSystem === 'METRICAL' ? 'm' : 'ft';
            default:
                return '';
        }

    }

    const updatePricingSummaryItemCall = (item, value) => {
        const newItem = {...item}
        newItem.value = value
        dispatch(updatePricingSummaryItem({projectId: projectId, pricingSummaryItem: newItem}))
    }

    const updateMouldingTypeMaterial = (item, value) => {
        const newItem = {...item}
        if (pricingLevel === 'LEVEL_1') newItem.level1Cost = value * pricingFactorLength
        if (pricingLevel === 'LEVEL_2') newItem.level2Cost = value * pricingFactorLength
        if (pricingLevel === 'LEVEL_3') newItem.level3Cost = value * pricingFactorLength
        if (pricingLevel === 'LEVEL_4') newItem.level4Cost = value * pricingFactorLength

        dispatch(updateMouldingType({
            projectId: projectId,
            mouldingType: newItem
        })).then(() => dispatch(refreshPricingSummary({projectId: projectId})))
    }

    const updateOpeningPrice = (value) => {
        if (pricingLevel === 'LEVEL_1') {
            const l1Defaults = JSON.parse(JSON.stringify(defaultsLevel1));
            if (l1Defaults.opening.isPricePerArea) {
                l1Defaults.opening.pricePerArea = value * pricingFactorArea
            } else {
                l1Defaults.opening.pricePerPiece = value * pricingFactorArea
            }
            dispatch(updateLvl1GeneralConfig({projectId: projectId, generalConfigLevel1: l1Defaults}))
                .then(() => dispatch(refreshPricingSummary({projectId: projectId})))
        }

        if (pricingLevel === 'LEVEL_2') {
            let item = pricingCategoriesLevel2.find((category) => category.id === 'openings').pricingItems.find((item) => item.id === 'window')
            item = JSON.parse(JSON.stringify(item));
            item.materialCost = value * pricingFactorArea
            item.laborCost = -1
            item.returnDepthEdge = -1
            item.returnDepthWindow = -1
            dispatch(updateSingleLevel2PricingItem({
                projectId: projectId,
                pricingItem: item
            }))
                .then(() => dispatch(refreshPricingSummary({projectId: projectId})))
        }
        if (pricingLevel === 'LEVEL_3') {
            let item = pricingCategoriesLevel3.find((category) => category.id === 'openings').pricingItems.find((item) => item.id === 'window')
            item = JSON.parse(JSON.stringify(item));
            item.materialCost = value * pricingFactorArea
            item.laborCost = -1
            item.returnDepthEdge = -1
            item.returnDepthWindow = -1
            dispatch(updateSingleLevel3PricingItem({
                projectId: projectId,
                pricingItem: item
            }))
                .then(() => dispatch(refreshPricingSummary({projectId: projectId})))
        }
        if (pricingLevel === 'LEVEL_4') {
            const l4Defaults = JSON.parse(JSON.stringify(defaultsLevel4));
            if (l4Defaults.opening.isPricePerArea) {
                l4Defaults.opening.pricePerArea = value * pricingFactorArea
            } else {
                l4Defaults.opening.pricePerPiece = value * pricingFactorArea
            }
            dispatch(updateLvl4GeneralConfig({projectId: projectId, generalConfigLevel4: l4Defaults}))
                .then(() => dispatch(refreshPricingSummary({projectId: projectId})))
        }


    }

    const updateOpeningLabor = (value) => {

        if (pricingLevel === 'LEVEL_3') {
            let item = pricingCategoriesLevel3.find((category) => category.id === 'openings').pricingItems.find((item) => item.id === 'window')
            item = JSON.parse(JSON.stringify(item));
            item.materialCost = -1
            item.laborCost = value * pricingFactorArea
            item.returnDepthEdge = -1
            item.returnDepthWindow = -1
            dispatch(updateSingleLevel3PricingItem({
                projectId: projectId,
                pricingItem: item
            }))
                .then(() => dispatch(refreshPricingSummary({projectId: projectId})))
        }
        if (pricingLevel === 'LEVEL_4') {
            const l4Defaults = JSON.parse(JSON.stringify(defaultsLevel4));
            if (l4Defaults.openingLabor.isPricePerArea) {
                l4Defaults.openingLabor.pricePerArea = value
            } else {
                l4Defaults.openingLabor.pricePerPiece = value
            }
            dispatch(updateLvl4GeneralConfig({projectId: projectId, generalConfigLevel4: l4Defaults}))
                .then(() => dispatch(refreshPricingSummary({projectId: projectId})))
        }


    }

    const updateFieldappPrice = (value) => {

        if (pricingLevel === 'LEVEL_2') {
            let item = pricingCategoriesLevel2.find((category) => category.id === 'fieldapplication').pricingItems.find((item) => item.id === 'fieldApplication')
            item = JSON.parse(JSON.stringify(item));
            item.materialCost = value * pricingFactorArea
            item.laborCost = -1
            item.returnDepthEdge = -1
            item.returnDepthWindow = -1
            dispatch(updateSingleLevel2PricingItem({
                projectId: projectId,
                pricingItem: item
            }))
                .then(() => dispatch(refreshPricingSummary({projectId: projectId})))
        }
        if (pricingLevel === 'LEVEL_3') {
            let item = pricingCategoriesLevel3.find((category) => category.id === 'fieldapplication').pricingItems.find((item) => item.id === 'fieldApplication')
            item = JSON.parse(JSON.stringify(item));
            item.materialCost = value * pricingFactorArea
            item.laborCost = -1
            item.returnDepthEdge = -1
            item.returnDepthWindow = -1
            dispatch(updateSingleLevel3PricingItem({
                projectId: projectId,
                pricingItem: item
            }))
                .then(() => dispatch(refreshPricingSummary({projectId: projectId})))
        }
        if (pricingLevel === 'LEVEL_4') {
            const l4Defaults = JSON.parse(JSON.stringify(defaultsLevel4));
            l4Defaults.fieldAppliedBasePrice = value * pricingFactorArea
            dispatch(updateLvl4GeneralConfig({projectId: projectId, generalConfigLevel4: l4Defaults}))
                .then(() => dispatch(refreshPricingSummary({projectId: projectId})))
        }
    }

    const updateMouldingTypeLabor = (item, value) => {
        const newItem = {...item}
        if (pricingLevel === 'LEVEL_3') newItem.level3Labor = value * pricingFactorLength
        if (pricingLevel === 'LEVEL_4') newItem.level4Labor = value * pricingFactorLength

        dispatch(updateMouldingType({
            projectId: projectId,
            mouldingType: newItem
        })).then(() => dispatch(refreshPricingSummary({projectId: projectId})))
    }

    const updateStorage = (value) => {
        const l4Defaults = JSON.parse(JSON.stringify(defaultsLevel4));
        l4Defaults.storage = value * pricingFactorArea
        dispatch(updateLvl4GeneralConfig({projectId: projectId, generalConfigLevel4: l4Defaults}))
            .then(() => dispatch(refreshPricingSummary({projectId: projectId})))
    }

    const updateExternalEngineering = (value) => {
        const l4Defaults = JSON.parse(JSON.stringify(defaultsLevel4));
        l4Defaults.externalEngineering = value * pricingFactorArea
        dispatch(updateLvl4GeneralConfig({projectId: projectId, generalConfigLevel4: l4Defaults}))
            .then(() => dispatch(refreshPricingSummary({projectId: projectId})))
    }

    const updateInternalEngineering = (value) => {
        const l4Defaults = JSON.parse(JSON.stringify(defaultsLevel4));
        l4Defaults.internalEngineering = value * pricingFactorArea
        dispatch(updateLvl4GeneralConfig({projectId: projectId, generalConfigLevel4: l4Defaults}))
            .then(() => dispatch(refreshPricingSummary({projectId: projectId})))
    }

    const updateProjectManagement = (value) => {
        const l4Defaults = JSON.parse(JSON.stringify(defaultsLevel4));
        l4Defaults.projectManagement = value * pricingFactorArea
        dispatch(updateLvl4GeneralConfig({projectId: projectId, generalConfigLevel4: l4Defaults}))
            .then(() => dispatch(refreshPricingSummary({projectId: projectId})))
    }

    const getFont = (item) => {
        if (isResult) {
            return classes.pricingItemFontBold;
        }
        switch (item.method) {
            case 'SALES':
                return classes.pricingItemFontBold;
            default:
                return classes.pricingItemFont;
        }
    }

    const getBox = (item) => {
        if (lastElement) {
            return classes.lastBox;
        }
        switch (item.method) {
            case 'SALES':
                return classes.lastBox;
            default:
                return '';
        }
    }

    const getPrice = (item) => {

        if (item === undefined)
            return null
        if (item.categoryId === "moulding") {

            let mouldingCost = 0;
            if (mouldingType !== undefined) {
                if (pricingLevel === 'LEVEL_1') mouldingCost = mouldingType.level1Cost
                if (pricingLevel === 'LEVEL_2') mouldingCost = mouldingType.level2Cost
                if (pricingLevel === 'LEVEL_3') mouldingCost = mouldingType.level3Cost
                if (pricingLevel === 'LEVEL_4') mouldingCost = mouldingType.level4Cost
            }
            mouldingCost = mouldingCost / pricingFactorLength;

            return (<>
                    <StoInput title={''}
                              onBlur={(event) => updateMouldingTypeMaterial(mouldingType, event.target.value)}
                              className={classes.inlineInput} value={mouldingCost.toFixed(2)} style={{
                        width: '6rem',
                        paddingRight: 0,
                        textAlign: 'right',
                        marginLeft: '1rem'
                    }}></StoInput>
                    <Typography className={`${getFont(item)} ${classes.pricingInput}`} style={{
                        width: '5rem',
                        marginRight: '1rem'
                    }}>$ per {getUnit(item)}</Typography>
                </>
            )
        } else if (item.categoryId === "mouldingLabor") {
            let laborCost = 0;
            if (mouldingType !== undefined) {
                if (pricingLevel === 'LEVEL_3') laborCost = mouldingType.level3Labor
                if (pricingLevel === 'LEVEL_4') laborCost = mouldingType.level4Labor
            }
            laborCost = laborCost / pricingFactorLength;
            return (
                <>
                    <StoInput title={''}
                              onBlur={(event) => updateMouldingTypeLabor(mouldingType, event.target.value)}
                              className={classes.inlineInput} value={laborCost.toFixed(2)} style={{
                        width: '6rem',
                        paddingRight: 0,
                        textAlign: 'right',
                        marginLeft: '1rem'
                    }}></StoInput>
                    <Typography className={`${getFont(item)} ${classes.pricingInput}`} style={{
                        width: '5rem',
                        marginRight: '1rem'
                    }}>$ per {getUnit(item)}</Typography>
                </>
            )
        } else if (item.categoryId === "opening") {
            let openingCost = 0;
            if (pricingLevel === 'LEVEL_1') openingCost = defaultsLevel1.opening.isPricePerArea ? defaultsLevel1.opening.pricePerArea : defaultsLevel1.opening.pricePerPiece
            if (pricingLevel === 'LEVEL_2') {
                openingCost = pricingCategoriesLevel2.find((category) => category.id === 'openings').pricingItems.find((item) => item.id === 'window').materialCost

            }
            if (pricingLevel === 'LEVEL_3') {
                openingCost = pricingCategoriesLevel3.find((category) => category.id === 'openings').pricingItems.find((item) => item.id === 'window').materialCost
            }
            if (pricingLevel === 'LEVEL_4') openingCost = defaultsLevel4.opening.isPricePerArea ? defaultsLevel4.opening.pricePerArea : defaultsLevel4.opening.pricePerPiece
            openingCost = openingCost / pricingFactorArea;
            return (
                <>
                    <StoInput title={''}
                              onBlur={(event) => updateOpeningPrice(event.target.value)}
                              className={classes.inlineInput} value={openingCost.toFixed(2)} style={{
                        width: '6rem',
                        paddingRight: 0,
                        textAlign: 'right',
                        marginLeft: '1rem'
                    }}></StoInput>
                    <Typography className={`${getFont(item)} ${classes.pricingInput}`} style={{
                        width: '5rem',
                        marginRight: '1rem'
                    }}>$ per {getUnit(item)}</Typography>
                </>
            )
        } else if (item.categoryId === "openingLabor") {
            let openingCost = 0;
            if (pricingLevel === 'LEVEL_3') {
                openingCost = pricingCategoriesLevel3.find((category) => category.id === 'openings').pricingItems.find((item) => item.id === 'window').laborCost
            }
            if (pricingLevel === 'LEVEL_4') openingCost = defaultsLevel4.opening.isPricePerArea ? defaultsLevel4.openingLabor.pricePerArea : defaultsLevel4.openingLabor.pricePerPiece
            openingCost = openingCost / pricingFactorArea;
            return (
                <>
                    <StoInput title={''}
                              onBlur={(event) => updateOpeningLabor(event.target.value)}
                              className={classes.inlineInput} value={openingCost.toFixed(2)} style={{
                        width: '6rem',
                        paddingRight: 0,
                        textAlign: 'right',
                        marginLeft: '1rem'
                    }}></StoInput>
                    <Typography className={`${getFont(item)} ${classes.pricingInput}`} style={{
                        width: '5rem',
                        marginRight: '1rem'
                    }}>$ per {getUnit(item)}</Typography>
                </>
            )
        } else if (item.categoryId === "storage" && pricingLevel === 'LEVEL_4') {

            let storageCost = 0
            if (pricingLevel === 'LEVEL_4') storageCost = defaultsLevel4.storage
            storageCost = storageCost / pricingFactorArea;

            return (<>
                    <StoInput title={''}
                              onBlur={(event) => updateStorage(event.target.value)}
                              className={classes.inlineInput} value={storageCost.toFixed(2)} style={{
                        width: '6rem',
                        paddingRight: 0,
                        textAlign: 'right',
                        marginLeft: '1rem'
                    }}></StoInput>
                    <Typography className={`${getFont(item)} ${classes.pricingInput}`} style={{
                        width: '5rem',
                        marginRight: '1rem'
                    }}>$ per {getUnit(item)}</Typography>
                </>
            )
        } else if (item.categoryId === "fieldapp" && pricingLevel !== "LEVEL_1") {
            let fieldappcost = 0;
            fieldappcost = 0;
            switch(pricingLevel){
                case "LEVEL_2":
                    fieldappcost = pricingCategoriesLevel2.find((category) => category.id === 'fieldapplication').pricingItems.find((item) => item.id === 'fieldApplication').materialCost
                    break;
                case "LEVEL_3":
                    fieldappcost = pricingCategoriesLevel3.find((category) => category.id === 'fieldapplication').pricingItems.find((item) => item.id === 'fieldApplication').materialCost
                    break;
                default:
                    fieldappcost = defaultsLevel4.fieldAppliedBasePrice
            }
            fieldappcost = fieldappcost / pricingFactorArea;

            return (
                <>
                    <StoInput title={''}
                              onBlur={(event) => updateFieldappPrice(event.target.value)}
                              className={classes.inlineInput} value={fieldappcost.toFixed(2)} style={{
                        width: '6rem',
                        paddingRight: 0,
                        textAlign: 'right',
                        marginLeft: '1rem'
                    }}></StoInput>
                    <Typography className={`${getFont(item)} ${classes.pricingInput}`} style={{
                        width: '5rem',
                        marginRight: '1rem'
                    }}>$ per {getUnit(item)}</Typography>
                </>
            )
        } else if (item.categoryId === "engineering" && pricingLevel === "LEVEL_4") {
            let cost = 0;
            cost = defaultsLevel4.externalEngineering
            cost = cost / pricingFactorArea;
            return (
                <>
                    <StoInput title={''}
                              onBlur={(event) => updateExternalEngineering(event.target.value)}
                              className={classes.inlineInput} value={cost.toFixed(2)} style={{
                        width: '6rem',
                        paddingRight: 0,
                        textAlign: 'right',
                        marginLeft: '1rem'
                    }}></StoInput>
                    <Typography className={`${getFont(item)} ${classes.pricingInput}`} style={{
                        width: '5rem',
                        marginRight: '1rem'
                    }}>$ per {getUnit(item)}</Typography>
                </>
            )
        } else if (item.categoryId === "engineeringinternal" && pricingLevel === "LEVEL_4") {
            let cost = 0;
            cost = defaultsLevel4.internalEngineering
            cost = cost / pricingFactorArea
            return (
                <>
                    <StoInput title={''}
                              onBlur={(event) => updateInternalEngineering(event.target.value)}
                              className={classes.inlineInput} value={cost.toFixed(2)} style={{
                        width: '6rem',
                        paddingRight: 0,
                        textAlign: 'right',
                        marginLeft: '1rem'
                    }}></StoInput>
                    <Typography className={`${getFont(item)} ${classes.pricingInput}`} style={{
                        width: '5rem',
                        marginRight: '1rem'
                    }}>$ per {getUnit(item)}</Typography>
                </>
            )
        } else if (item.categoryId === "projectmanagement" && pricingLevel === "LEVEL_4") {
            let cost = 0;
            cost = defaultsLevel4.projectManagement
            cost = cost / pricingFactorArea;
            return (
                <>
                    <StoInput title={''}
                              onBlur={(event) => updateProjectManagement(event.target.value)}
                              className={classes.inlineInput} value={cost.toFixed(2)} style={{
                        width: '6rem',
                        paddingRight: 0,
                        textAlign: 'right',
                        marginLeft: '1rem'
                    }}></StoInput>
                    <Typography className={`${getFont(item)} ${classes.pricingInput}`} style={{
                        width: '5rem',
                        marginRight: '1rem'
                    }}>$ per {getUnit(item)}</Typography>
                </>
            )
        } else {
            return (
                <Typography className={`${getFont(item)} ${classes.pricingInput}`} style={{
                    width: '12rem',
                    marginRight: '1rem'
                }}>{parseFloat(!isNaN(item.referenceValue) ? item.referenceValue : 0).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })} $ per {getUnit(item)}</Typography>
            )
        }
    }


    return (


        <div className={`${classes.innerBox} ${getBox(item)}`} style={{backgroundColor: backgroundColor}} key={key}>

            <Typography className={`${getFont(item)} ${classes.pricingLabel}`}>{item.name}
                {item.components != null && item.price > 0 &&
                    <span key={item.id}>
                                    <CustomTooltip title={<><h1>Components</h1><span
                                        className={"tooltipText"}>{getComponents(item)}</span></>} arrow={true}
                                                   placement={"bottom-start"}>
                                        <img src={infoIcon} className={classes.tooltipIcon} alt={"i"}/>
                                    </CustomTooltip></span>
                }
            </Typography>

            {((item.method === 'SUM' || item.method === 'COST' || item.method === 'SALES') && !(isResult && item.unit === "PERCENTAGE")) &&
                <>


                    <Typography className={`${getFont(item)} ${classes.pricingInput} `}
                                style={{width: '8rem'}}>{parseFloat(item.value).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}</Typography>

                    <Typography className={`${getFont(item)} ${classes.pricingInput}`}
                                style={{width: '2rem'}}>{getUnit(item)}</Typography>
                    <Typography className={`${getFont(item)} ${classes.pricingInput}`}
                                style={{width: '5rem'}}>x</Typography>


                    {getPrice(item)}

                    <div className={classes.vspacerFat}></div>

                    <Typography className={`${getFont(item)} ${classes.pricingInput}`}
                                style={{width: '12rem'}}>{parseFloat(item.price).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })} $</Typography>
                    <div className={classes.vspacer}></div>


                </>}

            {
                (isResult && item.unit === "PERCENTAGE") && <>
                    <div style={{width: '28rem'}}></div>
                    <div className={classes.vspacerFat}></div>
                    <Typography className={`${getFont(item)} ${classes.pricingInput}`}
                                style={{width: '12rem'}}>{parseFloat(item.price).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })} $</Typography>
                    <div className={classes.vspacer}></div>
                </>
            }

            {(item.method === 'COST' || item.method === 'SALES' || isResult) &&
                <Typography className={`${getFont(item)} ${classes.pricingInput}`}
                            style={{width: '7rem'}}>{item.percentage.toFixed(2)} %</Typography>
            }


            {(item.method === 'CONTINGENCY' || item.method === 'OVERHEAD' || item.method === 'PROFIT') &&
                <>

                    {(item.method === 'CONTINGENCY' || item.method === 'PROFIT') &&

                        <StoInput title={''} onBlur={(event) => updatePricingSummaryItemCall(item, event.target.value)}
                                  className={classes.inlineInput} value={item.value.toFixed(2)} style={{
                            width: '6rem',
                            paddingRight: 0,
                            textAlign: 'right',
                            marginLeft: '2rem'
                        }}></StoInput>

                    }
                    {(item.method === 'OVERHEAD') &&
                        <Typography className={`${getFont(item)} ${classes.pricingInput} `}
                                    style={{width: '8rem'}}>{parseFloat(item.value).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })}</Typography>
                    }
                    <Typography className={`${getFont(item)} ${classes.pricingInput}`}
                                style={{width: '2rem'}}>{getUnit(item)}</Typography>
                    <div style={{width: '18rem'}}></div>
                    <div className={classes.vspacerFat}></div>
                    <Typography className={`${getFont(item)} ${classes.pricingInput}`}
                                style={{width: '12rem'}}>{parseFloat(item.price).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })} $</Typography>
                    <div className={classes.vspacer}></div>

                </>}


        </div>


    );
}

export default PricingSummaryItem;