import {FormControl, MenuItem, Select, Tooltip} from "@mui/material";
import {useEffect, useState} from "react";


function StoDropdown({
                         initialValue,
                         options,
                         style = {},
                         dropdownStyle = {},
                         fullWidth = true,
                         onChange = (value) => console.log(value),
                         disabled = false,
                         toolTip = null
                     }) {
    const [value, setValue] = useState(initialValue)

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue]);

    function handleChange(value) {
        setValue(value)
        onChange(value)
    }

    const formStyle = {
        ...style
    }

    const selectStyle = {
        height: '3.2rem',
        flexShrink: 0,
        ...dropdownStyle
    }

    function getContent() {
        return (<FormControl fullWidth={fullWidth} style={formStyle}>
            <Select value={value} onChange={event => handleChange(event.target.value)} style={selectStyle}
                    disabled={disabled}>
                {options.map((option, index) => {
                    return <MenuItem key={index} value={option}>{option}</MenuItem>
                })}
            </Select>
        </FormControl>)
    }

    return toolTip ?
        (<Tooltip
            title={toolTip}>{getContent()}</Tooltip>) :
        getContent()
}

export default StoDropdown;