import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {updateClientInfo} from "./clientInfoSlice";
import StoTextInput from "../utils/components/StoTextInput";
import {makeStyles} from "@mui/styles";


const useStyle = makeStyles(() => ({
    formular: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '2rem',
        width: '52.2rem',
        gap: '1rem',
    },
}))

function ClientInfo(props) {
    const classes = useStyle(props.theme);

    const dispatch = useDispatch();
    const projectId = useParams();

    const clientInfo = useSelector(state => state.clientInfo?.clientInfo)

    const {companyName, location, notes} = clientInfo ? clientInfo : {}
    const {country, city, postCode, address} = location ? location : {}

    const [newCompanyName, setCompanyName] = useState("")
    const [newCountry, setCountry] = useState("")
    const [newCity, setCity] = useState("")
    const [newPostCode, setPostCode] = useState("")
    const [newAddress, setAddress] = useState("")
    const [newNotes, setNotes] = useState("")

    useEffect(() => {
        if (!clientInfo) {
            return
        }

        setCompanyName(clientInfo?.companyName)
        setCountry(clientInfo?.location?.country)
        setCity(clientInfo?.location?.city)
        setPostCode(clientInfo?.location?.postCode)
        setAddress(clientInfo?.location?.address)
        setNotes(clientInfo?.notes)
    }, [clientInfo])

    function updateClient(newValue, oldValue) {
        if (newValue === oldValue) {
            return
        }

        const clientInfo = {
            companyName: newCompanyName,
            location: {
                country: newCountry,
                city: newCity,
                postCode: newPostCode,
                address: newAddress,
            },
            notes: newNotes
        }

        dispatch(updateClientInfo({
            projectId: projectId.projectId,
            clientInfo: clientInfo
        }))
    }

    const placeholder = "Please type here ..."

    return <div>
        <Typography variant={'h1'}>Client</Typography>
        <div className={classes.formular}>
            <StoTextInput onChange={(event) => setCompanyName(event.target.value)}
                          onBlur={(event) => updateClient(event.target.value, companyName)}
                          value={newCompanyName || ''}
                          theme={'padding'}
                          placeholder={placeholder}
                          title={'Company Name'}
                          padding={"0 0"}/>
            <StoTextInput onChange={(event) => setCountry(event.target.value)}
                          onBlur={(event) => updateClient(event.target.value, country)}
                          value={newCountry || ''}
                          theme={'padding'}
                          placeholder={placeholder}
                          title={'Country'}
                          padding={"0 0"}/>
            <StoTextInput onChange={(event) => setCity(event.target.value)}
                          onBlur={(event) => updateClient(event.target.value, city)}
                          value={newCity || ''}
                          theme={'padding'}
                          placeholder={placeholder}
                          title={'City'}
                          padding={"0 0"}/>
            <StoTextInput onChange={(event) => setPostCode(event.target.value)}
                          onBlur={(event) => updateClient(event.target.value, postCode)}
                          value={newPostCode || ''}
                          theme={'padding'}
                          placeholder={placeholder}
                          title={'Post Code'}
                          padding={"0 0"}/>
            <StoTextInput onChange={(event) => setAddress(event.target.value)}
                          onBlur={(event) => updateClient(event.target.value, address)}
                          value={newAddress || ''}
                          theme={'padding'}
                          placeholder={placeholder}
                          title={'Address'}
                          padding={"0 0"}/>
            <StoTextInput onChange={(event) => setNotes(event.target.value)}
                          onBlur={(event) => updateClient(event.target.value, notes)}
                          value={newNotes || ''}
                          theme={'padding'}
                          placeholder={placeholder}
                          multiline
                          title={'Notes'}
                          padding={"0 0"}/>
        </div>
    </div>

}

export default ClientInfo;