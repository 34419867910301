import React from 'react'
import './export.css'
import {nicerNumber} from './Helpers'

export const ExportPricingItem = (props) => {

    const item = props.item
    const unitSystem = props.unitSystem
    const bold = props.bold
    const currency = "$"
    const pricingLevel = props.pricingLevel

    function getRefSize(item, unitSystem) {
        if (item.unit === "AREA" || item.unit === "WINDOWAREA" || item.unit === "MOULDING" || item.unit === "FIELDAPPLIED" ) {
            if (unitSystem === 'METRICAL')
                return <><span className={"left"}>$</span> / m²</>
            else return <><span className={"left"}>$</span> / sqft</>
        } else if (item.unit === "LENGTH" || item.unit === "MOULDING") {
            if (unitSystem === 'METRICAL')
                return ' / m'
            else return ' / lft'
        } else if (item.unit === "N") {
            return 'x'}
        else {
            return "%"

        }
    }

    function getRefValue(item, unitSystem) {
        if (item.unit === "AREA") {
            if (unitSystem === 'METRICAL')
                return <>{nicerNumber(item.value, 2)} m²</>
            else return <>{nicerNumber(item.value, 2)} sqft</>
        } else if (item.unit === "WINDOWAREA") {
            if (unitSystem === 'METRICAL')
                return <>{nicerNumber(item.value, 2)} m²</>
            else return <>{nicerNumber(item.value, 2)} sqft</>
        } else if (item.unit === "LENGTH" || item.unit === "MOULDING") {
            if (unitSystem === 'METRICAL')
                return <>{nicerNumber(item.value, 2)} m</>
            else return <>{nicerNumber(item.value, 2)} lft</>
        }
    }
    var reference = item.referenceValue

    if (item.method === "CONTINGENCY" || item.method === "OVERHEAD" || item.method === "PROFIT" ) {
        reference = item.value
    }
    var name = <td className={bold ? "boldLeft" : 'alignLeft'}>{item.name}</td>
    var referenceSize = pricingLevel === "LEVEL_1" ? null : <td className={bold ? "bold" : ''}>{nicerNumber(reference, 2)} {getRefSize(item, unitSystem)}</td>
    if (reference === 0)
        referenceSize = <td></td>
    var referenceValue = pricingLevel === "LEVEL_1" ? null : <td className={bold ? "bold" : ''}>{getRefValue(item, unitSystem)}</td>
    var price = <td className={bold ? "bold" : ''}><span className={'left'}>{currency}</span>{nicerNumber(item.price, 2)}</td>
    var percentage = pricingLevel === "LEVEL_1" ? null : <td className={bold ? "bold" : ''}>{(item.percentage > 0) ? nicerNumber(item.percentage, 2)+" %" : null}</td>


    if (pricingLevel)

    return (

        <tr>
            {name}
            {referenceSize}
            {referenceValue}
            {price}
            {percentage}




        </tr>
    )
}