import {makeStyles} from "@mui/styles";
import PanelConfigurationSidebar from "./PanelConfigurationSidebar";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import PricingLevel4 from "../pricingLevel/components/pricingLevel4";
import PanelConfigurationByMaterial from "./PanelConfigurationByMaterial";
import {updateMaterialPanelConfiguration} from "../material/materialSlice";
import {useParams} from "react-router-dom";

const useStyle = makeStyles(() => ({
    panelConfiguration: {
        display: 'flex',
        boxSizing: 'border-box',
        height: '100%',
    },
    sidebar: {
        height: "100%",
        width: "30rem",
        borderRight: "solid .1rem #CCCCCC",
        boxSizing: 'border-box',
        overflowY: "auto",
    },

    contentArea: {
        overflowY: "auto",
        width: "calc(100% - 30rem)",
        boxSizing: "border-box",
    },

}))

function PanelConfiguration2() {
    const classes = useStyle()

    const {projectId} = useParams()
    const dispatch = useDispatch()

    const materials = useSelector(state => state.materials.materials)

    const [selectedMaterial, setSelectedMaterial] = useState(null)
    const [togglePanelConfiguration, setTogglePanelConfiguration] = useState(false)
    const [scrollToItem, setScrollToItem] = useState(null)

    const currentPanelConfiguration = selectedMaterial?.panelConfigurations?.find(pConfig => pConfig.panelTypeId === selectedMaterial.panelTypeIdLevel4)

    useEffect(() => {
        const material = selectedMaterial !== null
            ? materials.find(material => material.id === selectedMaterial.id)
            : materials.length > 0 ? materials[0] : null

        setSelectedMaterial(material)

        // eslint-disable-next-line
    }, [materials]);

    function updatePanelConfiguration(material, componentGroupDTO) {
        const materialPanelConfiguration = {
            material: material,
            stoComponentGroup: componentGroupDTO
        }

        dispatch(updateMaterialPanelConfiguration({
            projectId: projectId,
            materialPanelConfiguration: materialPanelConfiguration
        }))
    }

    function setMaterial(item, material) {
        if (item['id']) {
            setTogglePanelConfiguration(!togglePanelConfiguration)
            setSelectedMaterial(item)
        } else {
            const i = {...item}
            const splitName = i.name.split(' ')
            let name = splitName.length > 1 ? splitName[1] : ''
            for (let i = 2; i < splitName.length; i++) {
                name += " " + splitName[i]
            }
            i.name = name
            const scrollTo = {
                panelType: material?.panelTypeId,
                componentGroup: i
            }

            setSelectedMaterial(material)
            setTimeout(() => {
                setScrollToItem(scrollTo)
            }, 100)
        }
    }

    return <div className={classes.panelConfiguration}>
        <div className={classes.sidebar}>
            <PanelConfigurationSidebar materials={materials}
                                       selectedMaterial={selectedMaterial}
                                       setSelectedMaterial={setMaterial}/>
        </div>
        <div className={classes.contentArea}>
            <PanelConfigurationByMaterial material={selectedMaterial}/>
            <PricingLevel4 currentPanelConfiguration={currentPanelConfiguration}
                           togglePanelConfiguration={togglePanelConfiguration}
                           scrollToItem={scrollToItem}
                           showHeader={false}
                           updatePanelConfiguration={(panelConfiguration) => updatePanelConfiguration(selectedMaterial, panelConfiguration)}/>
        </div>
    </div>
}

export default PanelConfiguration2