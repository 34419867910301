import axios from "axios";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {setPricing, setPricingCategories} from "../unity/react-unity-adapter";
import {getMouldingTypes} from "../mouldingType/mouldingTypeSlice";

export const PRICING_LEVEL = {
    LEVEL_1: "LEVEL_1",
    LEVEL_2: "LEVEL_2",
    LEVEL_3: "LEVEL_3",
    LEVEL_4: "LEVEL_4"
}

export const getPricing = createAsyncThunk('pricing/get', async ({projectId}) => {
    try {
        console.log('Get pricing: ', projectId)
        return await axios.get(`/api/projects/${projectId}/pricing`)
    } catch (err) {
        return err.message
    }
})

export const updatePanelTypePricing = createAsyncThunk('pricing/level1/update', async ({
                                                                                           projectId,
                                                                                           panelTypePrices
                                                                                       }) => {
    try {
        console.log('Update panel type prices: ', projectId, panelTypePrices)
        return await axios.put(`/api/projects/${projectId}/pricing/level1`, panelTypePrices)
    } catch (err) {
        return err.message
    }
})

export const updateSinglePanelTypePricing =
    createAsyncThunk('pricing/level1/updateSingle', async ({projectId, panelTypeId, panelType, panelTypePrice}) => {
        try {
            console.log('Update single panel type price: ', projectId, panelTypeId, panelType, panelTypePrice)
            return await axios.put(`/api/projects/${projectId}/pricing/level1/single`, {
                panelId: panelTypeId,
                lookTypeName: panelType, price: panelTypePrice
            })

        } catch (err) {
            return err.message
        }
    })

export const updatePricingCategories = createAsyncThunk('pricing/level3/update', async ({
                                                                                            projectId,
                                                                                            updatePricingCategories
                                                                                        }) => {
    try {
        console.log('Update pricing category: ', projectId, updatePricingCategories)
        return await axios.put(`/api/projects/${projectId}/pricing/level3`, updatePricingCategories)
    } catch (err) {
        return err.message
    }
})


export const updateSingleLevel3PricingItem = createAsyncThunk('pricing/level3/updateSingle', async ({
                                                                                                        projectId,
                                                                                                        pricingItem
                                                                                                    }, thunkAPI) => {
    try {
        console.log('Update pricing item level 3: ', projectId, pricingItem)
        const result = await axios.put(`/api/projects/${projectId}/pricing/level3/single`, pricingItem)
        thunkAPI.dispatch(getMouldingTypes({projectId}))
        thunkAPI.dispatch(getPricing({projectId}))
        return result
    } catch (err) {
        return err.message
    }
})

export const resetLvl1ToCompanyDefault = createAsyncThunk('pricing/level1/reset', async ({projectId}) => {
    try {
        console.log(projectId)
        return await axios.get(`/api/projects/${projectId}/pricing/reset/level1`, {params: {projectId: projectId}})
    } catch (err) {
        return err.message
    }
})

export const resetLvl2ToCompanyDefault = createAsyncThunk('pricing/level2/reset', async ({projectId}) => {
    try {
        return await axios.get(`/api/projects/${projectId}/pricing/reset/level2`, {params: {projectId: projectId}})
    } catch (err) {
        return err.message
    }
})

export const resetLvl3ToCompanyDefault = createAsyncThunk('pricing/level3/reset', async ({projectId}) => {
    try {
        return await axios.get(`/api/projects/${projectId}/pricing/reset/level3`, {params: {projectId: projectId}})
    } catch (err) {
        return err.message
    }
})

export const resetLvl4ToCompanyDefault = createAsyncThunk('pricing/level4/reset', async ({projectId}) => {
    try {
        return await axios.get(`/api/projects/${projectId}/resetPanelConfigurations`, {params: {projectId: projectId}})
    } catch (err) {
        return err.message
    }
})

export const updateLvl1CompanyPricing = createAsyncThunk('pricing/level1/getCompany', async ({companyId, pricing}) => {
    try {
        return await axios.put(`/api/company/pricing/lvl1`, pricing, {params: {companyId: companyId}})
    } catch (err) {
        return err.message
    }
})

export const updateLvl2CompanyPricing = createAsyncThunk('pricing/level2/getCompany', async ({companyId, pricing}) => {
    try {
        return await axios.put(`/api/company/pricing/lvl2`, pricing, {params: {companyId: companyId}})
    } catch (err) {
        return err.message
    }
})


export const updateLvl3CompanyPricing = createAsyncThunk('pricing/level3/getCompany', async ({companyId, pricing}) => {
    try {
        console.log(pricing)
        return await axios.put(`/api/company/pricing/lvl3`, pricing, {params: {companyId: companyId}})
    } catch (err) {
        return err.message
    }
})

export const updateLvl4CompanyPricing = createAsyncThunk('pricing/level4/getCompany', async ({
                                                                                                 companyId,
                                                                                                 panelConfig
                                                                                             }) => {
    try {
        return await axios.put('/api/company/pricing/lvl4', panelConfig, {params: {companyId: companyId}})
    } catch (err) {
        return err.message
    }
})

export const updateSingleLevel2PricingItem = createAsyncThunk('pricing/level2/updateSingle', async ({
                                                                                                        projectId,
                                                                                                        pricingItem
                                                                                                    }, thunkAPI) => {
    try {
        console.log('Update pricing item level 2: ', projectId, pricingItem)
        const result = await axios.put(`/api/projects/${projectId}/pricing/level2/single`, pricingItem)
        thunkAPI.dispatch(getMouldingTypes({projectId}))
        return result
    } catch (err) {
        return err.message
    }

})

export const updateGeneralPanelConfigurations = createAsyncThunk('pricing/panelConfigurations/update', async ({
                                                                                                                  projectId,
                                                                                                                  componentGroupDTO
                                                                                                              }) => {
    try {
        return await axios.put(`/api/projects/${projectId}/pricing/level4/single`, componentGroupDTO)
    } catch (err) {
        return err.message
    }
})

export const updateLvl1GeneralConfig = createAsyncThunk('pricing/level1/updateGeneral', async ({
                                                                                                   projectId,
                                                                                                   generalConfigLevel1
                                                                                               }) => {
    try {
        return await axios.put(`/api/projects/${projectId}/pricing/level1/general`, generalConfigLevel1)
    } catch (err) {
        return err.message
    }
})

export const updateLvl4GeneralConfig = createAsyncThunk('pricing/level4/updateGeneral', async ({
                                                                                                   projectId,
                                                                                                   generalConfigLevel4
                                                                                               }) => {
    try {
        return await axios.put(`/api/projects/${projectId}/pricing/level4/general`, generalConfigLevel4)
    } catch (err) {
        return err.message
    }
})

const pricingSlice = createSlice({
    name: 'pricing',
    initialState: {
        generalConfigLevel1: {},
        panelConfigurations: [],
        pricingCategoriesLevel2: [],
        pricingCategoriesLevel3: [],
        panelTypePrices: [],
        generalConfigLevel4: {},
        singleUpdate: false,
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getPricing.pending, (state) => {
                state.loading = true
            })
            .addCase(getPricing.fulfilled, (state, {payload}) => {
                state.panelTypePrices = payload.data?.panelTypePrices
                state.generalConfigLevel1 = payload.data?.generalConfigLevel1
                state.pricingCategoriesLevel2 = payload.data?.pricingCategoriesLevel2
                state.pricingCategoriesLevel3 = payload.data?.pricingCategoriesLevel3
                state.panelConfigurations = payload.data?.panelConfigurations
                state.generalConfigLevel4 = payload.data?.generalConfigLevel4
                state.newestPanelConfigurationTimestamp = payload.data?.newestPanelConfigurationTimestamp
                state.singleUpdate = false
                state.loading = false
                const returnObject = {
                    pricingCategoriesLevel2: state.pricingCategoriesLevel2,
                    panelTypePrices: state.panelTypePrices,
                    generalConfigLevel1: state.generalConfigLevel1,
                    pricingCategoriesLevel3: state.pricingCategoriesLevel3,
                    panelConfigurations: state.panelConfigurations,
                    generalConfigLevel4: state.generalConfigLevel4,
                    newestPanelConfigurationTimestamp: state.newestPanelConfigurationTimestamp
                }
                setPricing(returnObject)
            })
            .addCase(getPricing.rejected, (state) => {
                state.loading = false
            })

            .addCase(updateLvl1GeneralConfig.pending, (state) => {
                state.loading = true
            })
            .addCase(updateLvl1GeneralConfig.fulfilled, (state, {payload}) => {
                const generalConfigLevel1 = payload.data

                if (generalConfigLevel1) {
                    state.generalConfigLevel1 = generalConfigLevel1
                }

                state.loading = false
                setPricing(state)
            })
            .addCase(updateLvl1GeneralConfig.rejected, (state) => {
                state.loading = false
            })

            .addCase(updateLvl4GeneralConfig.pending, (state) => {
                state.loading = true
            })
            .addCase(updateLvl4GeneralConfig.fulfilled, (state, {payload}) => {
                const generalConfigLevel4 = payload.data

                if (generalConfigLevel4) {
                    state.generalConfigLevel4 = generalConfigLevel4
                }

                state.loading = false
                setPricing(state)
            })
            .addCase(updateLvl4GeneralConfig.rejected, (state) => {
                state.loading = false
            })

            .addCase(updatePricingCategories.pending, (state) => {
                state.loading = true
            })
            .addCase(updatePricingCategories.fulfilled, (state, {payload}) => {
                const {data: pricingCategories} = payload

                state.pricingCategoriesLevel3 = pricingCategories
                state.singleUpdate = false
                state.loading = false
                setPricingCategories(pricingCategories)
            })
            .addCase(updatePricingCategories.rejected, (state) => {
                state.loading = false
            })

            .addCase(updateSingleLevel3PricingItem.pending, (state) => {
                state.loading = true
                state.singleUpdate = true
            })
            .addCase(updateSingleLevel3PricingItem.fulfilled, (state, {payload}) => {
                const {data: pricingCategories} = payload

                state.pricingCategoriesLevel3 = pricingCategories
                state.singleUpdate = true
                state.loading = false
                setPricingCategories(pricingCategories)
            })
            .addCase(updateSingleLevel3PricingItem.rejected, (state) => {
                state.loading = false
                state.singleUpdate = false
            })
            .addCase(updateSingleLevel2PricingItem.pending, (state) => {
                state.loading = true
                state.singleUpdate = true
            })
            .addCase(updateSingleLevel2PricingItem.fulfilled, (state, {payload}) => {
                const {data: pricingCategories} = payload

                state.pricingCategoriesLevel2 = pricingCategories
                state.singleUpdate = true
                state.loading = false
                //setPricingCategories(pricingCategories)
            })
            .addCase(updateSingleLevel2PricingItem.rejected, (state) => {
                state.loading = false
                state.singleUpdate = false
            })

            .addCase(updateSinglePanelTypePricing.pending, (state) => {
                state.loading = true
                state.singleUpdate = true
            })
            .addCase(updateSinglePanelTypePricing.rejected, (state) => {
                state.loading = false
                state.singleUpdate = false
            })

            .addCase(updateSinglePanelTypePricing.fulfilled, (state, {payload}) => {
                const {data: panelTypePrices} = payload
                state.panelTypePrices = panelTypePrices
                state.singleUpdate = true
                state.loading = false
            })
            .addCase(updateGeneralPanelConfigurations.pending, (state) => {
                state.loading = true
            })
            .addCase(updateGeneralPanelConfigurations.fulfilled, (state, {payload}) => {
                const {data: panelConfigurations} = payload
                state.panelConfigurations = panelConfigurations
                state.loading = false
            })
            .addCase(updateGeneralPanelConfigurations.rejected, (state) => {
                state.loading = true
            })
            .addCase(resetLvl1ToCompanyDefault.pending, (state) => {
                state.loading = true
            })
            .addCase(resetLvl1ToCompanyDefault.fulfilled, (state, {payload}) => {
                const {data: panelTypePrices} = payload
                state.panelTypePrices = panelTypePrices
                state.loading = false
            })
            .addCase(resetLvl1ToCompanyDefault.rejected, (state) => {
                state.loading = false
            })

            .addCase(resetLvl2ToCompanyDefault.pending, (state) => {
                state.loading = true
            })
            .addCase(resetLvl2ToCompanyDefault.fulfilled, (state, {payload}) => {
                const {data: pricingCategories} = payload
                state.pricingCategoriesLevel2 = pricingCategories
                state.loading = false
            })
            .addCase(resetLvl2ToCompanyDefault.rejected, (state) => {
                state.loading = false
            })
            .addCase(resetLvl3ToCompanyDefault.pending, (state) => {
                state.loading = true
            })
            .addCase(resetLvl3ToCompanyDefault.fulfilled, (state, {payload}) => {
                const {data: pricingCategories} = payload
                state.pricingCategoriesLevel3 = pricingCategories
                state.loading = false
            })
            .addCase(resetLvl3ToCompanyDefault.rejected, (state) => {
                state.loading = false
            })

            .addCase(resetLvl4ToCompanyDefault.pending, (state) => {
                state.loading = true
            })
            .addCase(resetLvl4ToCompanyDefault.fulfilled, (state, {payload}) => {
                const {data: panelConfigurations} = payload
                console.log(panelConfigurations)
                state.panelConfigurations = panelConfigurations
                state.loading = false
            })
            .addCase(resetLvl4ToCompanyDefault.rejected, (state) => {
                state.loading = false
            })
    }
})
export const {setPricingLvl1} = pricingSlice.actions
export default pricingSlice.reducer