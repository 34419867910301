import React, {useState} from "react";
import {makeStyles} from "@mui/styles";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {FormControl, FormControlLabel, RadioGroup, Tooltip, Typography} from "@mui/material";
import Radio from "@mui/material/Radio";

import {updatePricingLevel} from "../../settings/settingsSlice";
import PricingSidebarNavigation from "./PricingSidebarNavigation";
import {
    PRICING_LEVEL,
    resetLvl1ToCompanyDefault,
    resetLvl2ToCompanyDefault,
    resetLvl3ToCompanyDefault,
    resetLvl4ToCompanyDefault,
    updateLvl1CompanyPricing,
    updateLvl2CompanyPricing,
    updateLvl3CompanyPricing,
    updateLvl4CompanyPricing
} from "../pricingSlice";
import StoIconButton from "../../utils/components/StoIconButton";
import StoSnackbar from "../../utils/components/StoSnackbar";
import {getToolTipTitle} from "../../utils/toolTipUtil";
import ResetIcon from "../../utils/icons/reset.svg";
import SaveIcon from "../../utils/icons/save.svg";
import ResetDoneIcon from "../../utils/icons/reset-done.svg";
import SaveDoneIcon from "../../utils/icons/save-done.svg";
import StoPrimaryButton from "../../utils/components/StoPrimaryButton";
import StoDialog from "../../utils/components/StoDialog";
import PricingSidebarNavigationLevel4 from "./PricingSidebarNavigationLevel4";

const useStyle = makeStyles(_ => ({
    sidebar: {
        height: "100%",
        width: "30rem",
        borderRight: "solid .1rem #CCCCCC",
        boxSizing: 'border-box',
        overflowY: "auto",
    },
    selectorBox: {
        boxSizing: 'border-box',
        borderBottom: "solid .1rem #CCCCCC",
    },
    categoryList: {
        overflowY: "auto",
        boxSizing: "border-box",
        bottom: "0"

    },
}));

function PricingSidebar({
                            setScrollToItem,
                            setScrollToItemLevel4,
                            pricingCategories,
                            activePanelType,
                            setActivePanelType
                        }) {
    const classes = useStyle();
    const isAdmin = useSelector(state => state.projects.userRoles).some(role => role === 'admin:all')
    const pricingLevel = useSelector(state => state.settings.settings.pricingLevel)
    const dispatch = useDispatch()
    const companyId = useSelector(state => state.projects.companyId)
    const {projectId} = useParams();
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const [snackbarIcon, setSnackbarIcon] = useState(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [saveOpen, setSaveOpen] = useState(false)

    const lvl1Pricing = useSelector(state => state.pricing.panelTypePrices)
    const lvl2Pricing = useSelector(state => state.pricing.pricingCategoriesLevel2)
    const lvl3Pricing = useSelector(state => state.pricing.pricingCategoriesLevel3)
    const lvl4Pricing = useSelector(state => state.pricing.panelConfigurations)

    const handleChange = (event) => {
        setScrollToItem(null)
        dispatch(updatePricingLevel({projectId: projectId, pricingLevel: event.target.value}))
    };

    const handleResetClick = () => {
        if (pricingLevel === PRICING_LEVEL.LEVEL_1) {
            dispatch(resetLvl1ToCompanyDefault({projectId: projectId})).then(() => {
                setSnackbarMessage("Level 1 pricing reset to company default")
                setSnackbarIcon(ResetDoneIcon)
                setSnackbarOpen(true)
            })
        } else if (pricingLevel === PRICING_LEVEL.LEVEL_2) {
            dispatch(resetLvl2ToCompanyDefault({projectId: projectId})).then(() => {
                setSnackbarMessage("Level 2 pricing reset to company default")
                setSnackbarIcon(ResetDoneIcon)
                setSnackbarOpen(true)
            })
        } else if (pricingLevel === PRICING_LEVEL.LEVEL_3) {
            dispatch(resetLvl3ToCompanyDefault({projectId: projectId})).then(() => {
                setSnackbarMessage("Level 3 pricing reset to company default")
                setSnackbarIcon(ResetDoneIcon)
                setSnackbarOpen(true)
            })
        } else if (pricingLevel === PRICING_LEVEL.LEVEL_4) {
            dispatch(resetLvl4ToCompanyDefault({projectId: projectId})).then(() => {
                setSnackbarMessage("Level 4 pricing reset to company default")
                setSnackbarIcon(ResetDoneIcon)
                setSnackbarOpen(true)
            })
        }
    }

    function openSaveDialog(event) {
        event.stopPropagation()
        setSaveOpen(true)
    }

    const closeSaveDialog = (event) => {
        event.stopPropagation()
        setSaveOpen(false)
    }
    const handleSaveClick = () => {

        setSaveOpen(false)
        if (pricingLevel === PRICING_LEVEL.LEVEL_1) {
            dispatch(updateLvl1CompanyPricing({companyId: companyId, pricing: lvl1Pricing})).then(() => {
                setSnackbarMessage("Saved current Level 1 pricing as company default")
                setSnackbarIcon(SaveDoneIcon)
                setSnackbarOpen(true)
            })
        } else if (pricingLevel === PRICING_LEVEL.LEVEL_2) {
            dispatch(updateLvl2CompanyPricing({companyId: companyId, pricing: lvl2Pricing})).then(() => {
                setSnackbarMessage("Saved current Level 2 pricing as company default")
                setSnackbarIcon(SaveDoneIcon)
                setSnackbarOpen(true)
            })
        } else if (pricingLevel === PRICING_LEVEL.LEVEL_3) {
            dispatch(updateLvl3CompanyPricing({companyId: companyId, pricing: lvl3Pricing})).then(() => {
                setSnackbarMessage("Saved current Level 3 pricing as company default")
                setSnackbarIcon(SaveDoneIcon)
                setSnackbarOpen(true)
            })
        } else if (pricingLevel === PRICING_LEVEL.LEVEL_4) {
            dispatch(updateLvl4CompanyPricing({companyId: companyId, panelConfig: lvl4Pricing})).then(() => {
                setSnackbarMessage("Saved current Level 4 pricing as company default")
                setSnackbarIcon(SaveDoneIcon)
                setSnackbarOpen(true)
            })
        }
    }

    const getPricingLvlAsText = () => {
        if (pricingLevel === PRICING_LEVEL.LEVEL_1) {
            return "Level 1"
        } else if (pricingLevel === PRICING_LEVEL.LEVEL_2) {
            return "Level 2"
        } else if (pricingLevel === PRICING_LEVEL.LEVEL_3) {
            return "Level 3"
        } else if (pricingLevel === PRICING_LEVEL.LEVEL_4) {
            return "Level 4"
        }
    }

    function getDialogContent() {
        return (<div>
            <Typography variant={"h1"} className={classes.deleteProjectTitle}>
                {"Are you sure you want to override your company standard settings?"}</Typography>
            <Typography variant={"subtitle1"} style={{paddingTop: "2rem"}}>
                {"This will override the company standard settings for all projects in your company."}</Typography>
        </div>)
    }

    function getDialogActions() {
        return (<div className={classes.dialogActions}>
            <StoPrimaryButton onClick={(event) => handleSaveClick(event)} title={"Save"}/>
            <StoPrimaryButton onClick={(event) => closeSaveDialog(event)} title={"Cancel"} secondary/>
        </div>)
    }

    return (
        <div className={classes.sidebar}>
            <StoSnackbar horizontal={'center'}
                         vertical={'top'}
                         onClose={() => setSnackbarOpen(false)}
                         message={snackbarMessage}
                         open={snackbarOpen}
                         icon={snackbarIcon}
                         duration={3000}/>
            <div className={classes.selectorBox}>
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    height: '4.8rem',
                    paddingLeft: '1.2rem',
                    paddingRight: '.8rem',
                    justifyContent: 'space-between',
                    borderBottom: '.1rem solid #cccccc'
                }}>
                    <Typography>Pricing Details</Typography>
                    <div style={{display: 'flex'}}>
                        <StoIconButton
                            icon={ResetIcon} tooltip={"Reset " + getPricingLvlAsText() + " pricing to company default"}
                            onClick={() => handleResetClick()}
                        />

                        {isAdmin && <StoIconButton
                            icon={SaveIcon}
                            tooltip={"Define current " + getPricingLvlAsText() + " pricing as company default"}
                            onClick={(event) => openSaveDialog(event)}
                        />}
                    </div>
                </div>

                <div style={{padding: '.8rem 1rem .8rem 1rem'}}>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="pricing" name="pricing1" value={pricingLevel}
                                    onChange={handleChange}>
                            <Tooltip
                                title={getToolTipTitle("Level 1 Pricing", "Setup a simple pricing configuration with one position for panel parts and one for finish types")}
                                arrow={true} placement={"right"} hidden={true}>
                                <FormControlLabel value={PRICING_LEVEL.LEVEL_1}
                                                  control={<Radio color="primary"/>} label="Level 1"/>
                            </Tooltip>
                            <Tooltip
                                title={getToolTipTitle("Level 2 Pricing", "Setup a very detailed pricing configuration with separated positions for panel parts, finish types and more by material")}
                                arrow={true} placement={"right"} hidden={true}>
                                <FormControlLabel value={PRICING_LEVEL.LEVEL_2}
                                                  control={<Radio color="primary"/>} label="Level 2"/>
                            </Tooltip>
                            <Tooltip
                                title={getToolTipTitle("Level 3 Pricing", "Setup a very detailed pricing configuration with separated positions for panel parts, finish types and more divided by material and labor")}
                                arrow={true} placement={"right"} hidden={true}>
                                <FormControlLabel value={PRICING_LEVEL.LEVEL_3}
                                                  control={<Radio color="primary"/>} label="Level 3"/>
                            </Tooltip>
                            <Tooltip
                                title={getToolTipTitle("Level 4 Pricing", "Setup a very detailed pricing configuration with separated positions for panel parts, finish types and more divided by material, labor and additional costs")}
                                arrow={true} placement={"right"} hidden={true}>
                                <FormControlLabel value={PRICING_LEVEL.LEVEL_4}
                                                  control={<Radio color="primary"/>} label="Level 4 (Beta)"/>
                            </Tooltip>
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
            {
                (pricingLevel !== PRICING_LEVEL.LEVEL_1 && pricingLevel !== PRICING_LEVEL.LEVEL_4) &&
                <div className={classes.categoryList}>
                    <PricingSidebarNavigation setScrollToItem={setScrollToItem}
                                              categories={pricingCategories}/>
                </div>
            }
            {
                pricingLevel === PRICING_LEVEL.LEVEL_4 &&
                <div className={classes.categoryList}>
                    <PricingSidebarNavigationLevel4 setScrollToItem={setScrollToItemLevel4}
                                                    activePanelType={activePanelType}
                                                    setActivePanelType={setActivePanelType}/>
                </div>
            }

            <StoDialog open={saveOpen} onClose={(event) => closeSaveDialog(event)}
                       title={""}
                       maxWidth={"36rem"}
                       content={getDialogContent()}
                       actions={getDialogActions()}/>
        </div>
    )
}

export default PricingSidebar