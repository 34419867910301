import {makeStyles} from "@mui/styles";
import React, {useEffect, useState} from "react";
import {Typography} from "@mui/material";

import './pricingLevel4.css'
import StoPricePerUnitInput from "../../utils/components/StoPricePerUnitInput";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {updateLvl4GeneralConfig} from "../pricingSlice";

const useStyle = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '50.8rem',
        gap: '.8rem',
        paddingTop: '1.6rem',
        paddingBottom: '1.6rem',
        paddingLeft: '3.2rem',
        paddingRight: '3.2rem',
        backgroundColor: '#f2f2f2',
        borderBottom: '0.25px solid #CCCCCC'
    },
    content: {
        width: 'calc(100% - 3.2rem)',
        padding: '1.6rem',
        backgroundColor: 'white',
    },
    materialProperties: {
        display: 'flex',
        flexDirection: 'column',
        width: 'calc(100% - 80.8rem)',
        gap: '.8rem',
        paddingLeft: '4.8rem',
    },
    pricingInput: {
        height: '2rem !important',

        '& .MuiInputBase-formControl': {
            height: '100%'
        }
    },
}))


function PricingLevel4General() {
    const classes = useStyle()

    const dispatch = useDispatch()
    const {projectId} = useParams()

    const generalConfigLevel4Store = useSelector(state => state.pricing?.generalConfigLevel4)

    const [generalConfigLevel4, setGeneralConfigLevel4] = useState(generalConfigLevel4Store)

    useEffect(() => {
        setGeneralConfigLevel4(generalConfigLevel4Store)
    }, [generalConfigLevel4Store]);

    const style = {
        minWidth: '50rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '3.6rem'
    }
    const textStyle = {width: '26.4rem'}
    const inputStyle = {
        height: '100%',
    }
/*
    function onChangeOpeningOption(value) {
        const newGeneralConfigLevel4 = JSON.parse(JSON.stringify(generalConfigLevel4))

        newGeneralConfigLevel4.opening.isPricePerArea = value === 0
        setGeneralConfigLevel4(newGeneralConfigLevel4)
        dispatch(updateLvl4GeneralConfig({projectId: projectId, generalConfigLevel4: newGeneralConfigLevel4}))
    }
*/
    function onChangeOpeningPricePerArea(value) {
        const newGeneralConfigLevel4 = JSON.parse(JSON.stringify(generalConfigLevel4))

        newGeneralConfigLevel4.opening.pricePerArea = value
        setGeneralConfigLevel4(newGeneralConfigLevel4)
        dispatch(updateLvl4GeneralConfig({projectId: projectId, generalConfigLevel4: newGeneralConfigLevel4}))
    }
/*
    function onChangeOpeningPricePerPiece(value) {
        const newGeneralConfigLevel4 = JSON.parse(JSON.stringify(generalConfigLevel4))

        newGeneralConfigLevel4.opening.pricePerPiece = value
        setGeneralConfigLevel4(newGeneralConfigLevel4)
        dispatch(updateLvl4GeneralConfig({projectId: projectId, generalConfigLevel4: newGeneralConfigLevel4}))
    }*/

    function onChangeOpeningLaborPerArea(value) {
        const newGeneralConfigLevel4 = JSON.parse(JSON.stringify(generalConfigLevel4))

        newGeneralConfigLevel4.openingLabor.pricePerArea = value
        setGeneralConfigLevel4(newGeneralConfigLevel4)
        dispatch(updateLvl4GeneralConfig({projectId: projectId, generalConfigLevel4: newGeneralConfigLevel4}))
    }

    /*
    function onChangeOpeningLaborPerPiece(value) {
        const newGeneralConfigLevel4 = JSON.parse(JSON.stringify(generalConfigLevel4))

        newGeneralConfigLevel4.openingLabor.pricePerPiece = value
        setGeneralConfigLevel4(newGeneralConfigLevel4)
        dispatch(updateLvl4GeneralConfig({projectId: projectId, generalConfigLevel4: newGeneralConfigLevel4}))
    }*/

    function onChangeMoulding(value) {
        const newGeneralConfigLevel4 = {...generalConfigLevel4}

        newGeneralConfigLevel4.moulding = value
        setGeneralConfigLevel4(newGeneralConfigLevel4)
        dispatch(updateLvl4GeneralConfig({projectId: projectId, generalConfigLevel4: newGeneralConfigLevel4}))
    }

    function onChangeOverhead(value) {
        const newGeneralConfigLevel4 = {...generalConfigLevel4}

        newGeneralConfigLevel4.generalOverhead = value
        setGeneralConfigLevel4(newGeneralConfigLevel4)
        dispatch(updateLvl4GeneralConfig({projectId: projectId, generalConfigLevel4: newGeneralConfigLevel4}))
    }

    function onChangeMouldingLabor(value) {
        const newGeneralConfigLevel4 = {...generalConfigLevel4}

        newGeneralConfigLevel4.mouldingLabor = value
        setGeneralConfigLevel4(newGeneralConfigLevel4)
        dispatch(updateLvl4GeneralConfig({projectId: projectId, generalConfigLevel4: newGeneralConfigLevel4}))
    }

    function onChangeFieldAppliedBasePrice(value) {
        const newGeneralConfigLevel4 = {...generalConfigLevel4}

        newGeneralConfigLevel4.fieldAppliedBasePrice = value
        setGeneralConfigLevel4(newGeneralConfigLevel4)
        dispatch(updateLvl4GeneralConfig({projectId: projectId, generalConfigLevel4: newGeneralConfigLevel4}))
    }

    function onChangeProjectManagement(value) {
        const newGeneralConfigLevel4 = {...generalConfigLevel4}

        newGeneralConfigLevel4.projectManagement = value
        setGeneralConfigLevel4(newGeneralConfigLevel4)
        dispatch(updateLvl4GeneralConfig({projectId: projectId, generalConfigLevel4: newGeneralConfigLevel4}))
    }

    function onChangeEngineeringExternal(value) {
        const newGeneralConfigLevel4 = {...generalConfigLevel4}

        newGeneralConfigLevel4.externalEngineering = value
        setGeneralConfigLevel4(newGeneralConfigLevel4)
        dispatch(updateLvl4GeneralConfig({projectId: projectId, generalConfigLevel4: newGeneralConfigLevel4}))
    }

    function onChangeEngineeringInternal(value) {
        const newGeneralConfigLevel4 = {...generalConfigLevel4}

        newGeneralConfigLevel4.internalEngineering = value
        setGeneralConfigLevel4(newGeneralConfigLevel4)
        dispatch(updateLvl4GeneralConfig({projectId: projectId, generalConfigLevel4: newGeneralConfigLevel4}))
    }

    function onChangeStorage(value) {
        const newGeneralConfigLevel4 = {...generalConfigLevel4}

        newGeneralConfigLevel4.storage = value
        setGeneralConfigLevel4(newGeneralConfigLevel4)
        dispatch(updateLvl4GeneralConfig({projectId: projectId, generalConfigLevel4: newGeneralConfigLevel4}))
    }

    const {opening, openingLabor} = generalConfigLevel4

    return <div className={classes.container}>
        <Typography variant={'bodyText'}>General Configurations</Typography>
        <div className={classes.content}>
            <div className={classes.materialProperties}>
                <StoPricePerUnitInput title={'General Overhead'} value={generalConfigLevel4?.generalOverhead}
                                      unit={'percent'}
                                      fullWidth={false}
                                      style={style}
                                      textStyle={textStyle}
                                      inputStyle={inputStyle}
                                      onBlur={onChangeOverhead}/>
                {/* <LabelTogglePrice title={'Opening Base Price'}
                                  option={opening?.isPricePerArea ? 0 : 1}
                                  onOptionChanged={onChangeOpeningOption}
                                  values={[opening?.pricePerArea, opening?.pricePerPiece]}
                                  onValuesChanged={[onChangeOpeningPricePerArea, onChangeOpeningPricePerPiece]}
                                  units={['perArea', 'perPiece']}/>

                <LabelTogglePrice title={'Opening Labor Price'}
                                  option={openingLabor?.isPricePerArea ? 0 : 1}
                                  onOptionChanged={onChangeOpeningOption}
                                  values={[openingLabor?.pricePerArea, openingLabor?.pricePerPiece]}
                                  onValuesChanged={[onChangeOpeningLaborPerArea, onChangeOpeningLaborPerPiece]}
                                  units={['perArea', 'perPiece']}/> */}
                <StoPricePerUnitInput title={'Opening Base Price'} value={opening?.pricePerArea}
                                      unit={'perArea'}
                                      fullWidth={false}
                                      style={style}
                                      textStyle={textStyle}
                                      inputStyle={inputStyle}
                                      onBlur={onChangeOpeningPricePerArea}/>
                <StoPricePerUnitInput title={'Opening Labor Price'} value={openingLabor?.pricePerArea}
                                      unit={'perArea'}
                                      fullWidth={false}
                                      style={style}
                                      textStyle={textStyle}
                                      inputStyle={inputStyle}
                                      onBlur={onChangeOpeningLaborPerArea}/>
                <StoPricePerUnitInput title={'Moulding Base Price'} value={generalConfigLevel4?.moulding}
                                      unit={'perArea'}
                                      fullWidth={false}
                                      style={style}
                                      textStyle={textStyle}
                                      inputStyle={inputStyle}
                                      onBlur={onChangeMoulding}/>
                <StoPricePerUnitInput title={'Moulding Labor Base Price'} value={generalConfigLevel4?.mouldingLabor}
                                      unit={'perArea'}
                                      fullWidth={false}
                                      style={style}
                                      textStyle={textStyle}
                                      inputStyle={inputStyle}
                                      onBlur={onChangeMouldingLabor}/>
                <StoPricePerUnitInput title={'Field Applied Base Price'}
                                      value={generalConfigLevel4?.fieldAppliedBasePrice} unit={'perArea'}
                                      fullWidth={false}
                                      style={style}
                                      textStyle={textStyle}
                                      inputStyle={inputStyle}
                                      onBlur={onChangeFieldAppliedBasePrice}/>
                <StoPricePerUnitInput title={'Storage and Shipping'} value={generalConfigLevel4?.storage}
                                      unit={'perArea'}
                                      fullWidth={false}
                                      style={style}
                                      textStyle={textStyle}
                                      inputStyle={inputStyle}
                                      onBlur={onChangeStorage}/>
                <StoPricePerUnitInput title={'External Engineering'} value={generalConfigLevel4?.externalEngineering}
                                      unit={'perArea'}
                                      fullWidth={false}
                                      style={style}
                                      textStyle={textStyle}
                                      inputStyle={inputStyle}
                                      onBlur={onChangeEngineeringExternal}/>
                <StoPricePerUnitInput title={'Design / Internal Engineering'} value={generalConfigLevel4?.internalEngineering}
                                      unit={'perArea'}
                                      fullWidth={false}
                                      style={style}
                                      textStyle={textStyle}
                                      inputStyle={inputStyle}
                                      onBlur={onChangeEngineeringInternal}/>
                <StoPricePerUnitInput title={'Project Management'} value={generalConfigLevel4?.projectManagement}
                                      unit={'perArea'}
                                      fullWidth={false}
                                      style={style}
                                      textStyle={textStyle}
                                      inputStyle={inputStyle}
                                      onBlur={onChangeProjectManagement}/>
            </div>
        </div>
    </div>
}

export default PricingLevel4General