import {makeStyles} from "@mui/styles";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Typography} from "@mui/material";
import {
    FloorMeasurementVariants, resetBuildingDimensionsToCompanyDefault,
    updateBuildingDimensions, updateCompanyDimensionSettings,
} from "./settingsSlice";

import SSL from '../assets/png/SSL.png';
import FFL from '../assets/png/FFL.png';
import StoImageOptions from "../utils/components/StoImageOptions";
import StoLengthInput from "../utils/components/StoLengthInput";
import React, {Fragment, useEffect, useState} from "react";
import StoCheckbox from "../utils/components/StoCheckbox";
import {getToolTipTitle} from "../utils/toolTipUtil";
import {unitSystems} from "../unitSystem/unitSystemSlice";
import StoSnackbar from "../utils/components/StoSnackbar";
import ResetDoneIcon from "../utils/icons/reset-done.svg";
import StoIconButton from "../utils/components/StoIconButton";
import ResetIcon from "../utils/icons/reset.svg";
import SaveIcon from "../utils/icons/save.svg";
import StoPrimaryButton from "../utils/components/StoPrimaryButton";
import StoDialog from "../utils/components/StoDialog";

const useStyle = makeStyles(() => ({
    formular: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '2rem',
        width: '52.2rem',
        gap: '1rem',
    },
}))

function Dimension() {
    const dispatch = useDispatch();
    const {projectId} = useParams();

    const unitSystem = useSelector(state => state.unitSystem.unitSystem)
    const minStep = unitSystems.METRICAL === unitSystem ? 0.05 : 0.0254
    const [resetValues, setResetValues] = useState(false)

    const [showSaveSnackbar, setShowSaveSnackbar] = useState(false)
    const [showResetSnackbar, setResetSaveSnackbar] = useState(false)
    const isAdmin = useSelector(state => state.projects.userRoles).some(role => role === 'admin:all')
    const companyId = useSelector(state => state.projects.companyId)

    const {
        defaultFloorHeight,
        overFinishFloorThickness,
        useOverFinishFloorThickness: useOverFinishFloor,
        defaultFinishedFloorThickness,
        defaultStructuralSlabThickness: defaultStructuralSlab,
        upperPartStructuralSlabThickness: upperPartStructuralSlab,
        useUpperPartStructuralSlabThickness: useUpperPartStructuralSlab,
        useLowerPartStructuralSlabThickness: useLowerPartStructuralSlab,
        useMiddlePartStructuralSlabThickness: useMiddlePartStructuralSlab,
        underStructuralSlabThickness,
        useUnderStructuralSlabThickness: useUnderStructuralSlab,
        groundStructuralSlabThickness,
        groundFinishedFloorThickness,
        parapetHeight,
        floorMeasurementVariant: measurement
    } = useSelector(state => state.settings.settings.buildingDimensions)

    const [useOverFinishFloorThickness, setUseOverFinishFloorThickness] = useState(useOverFinishFloor)

    const [defaultStructuralSlabThickness, setDefaultStructuralSlabThickness] = useState(defaultStructuralSlab)
    const [upperPartStructuralSlabThickness, setUpperPartStructuralSlabThickness] = useState(upperPartStructuralSlab)
    const [useUpperPartStructuralSlabThickness, setUseUpperPartStructuralSlabThickness] = useState(useUpperPartStructuralSlab)
    const [useLowerPartStructuralSlabThickness, setUseLowerPartStructuralSlabThickness] = useState(useLowerPartStructuralSlab)
    const [useMiddlePartStructuralSlabThickness, setUseMiddlePartStructuralSlabThickness] = useState(useMiddlePartStructuralSlab)
    const [useUnderStructuralSlabThickness, setUseUnderStructuralSlabThickness] = useState(useUnderStructuralSlab)
    const [floorMeasurementVariant, setFloorMeasurementVariant] = useState(measurement)
    const [saveOpen, setSaveOpen] = useState(false)
    const dimensionSettings = useSelector(state => state.settings.settings.buildingDimensions)



    const [settings] = useState({
        defaultFloorHeight: dimensionSettings.defaultFloorHeight,
        defaultStructuralSlabThickness: dimensionSettings.defaultStructuralSlabThickness,
        defaultFinishedFloorThickness: dimensionSettings.defaultFinishedFloorThickness,
        groundStructuralSlabThickness: dimensionSettings.groundStructuralSlabThickness,
        groundFinishedFloorThickness: dimensionSettings.groundFinishedFloorThickness,
        parapetHeight: dimensionSettings.parapetHeight,
        floorMeasurementVariant: dimensionSettings.floorMeasurementVariant,

        overFinishFloorThickness: dimensionSettings.overFinishFloorThickness,
        useOverFinishFloorThickness: dimensionSettings.useOverFinishFloorThickness,
        upperPartStructuralSlabThickness: dimensionSettings.upperPartStructuralSlabThickness,
        useUpperPartStructuralSlabThickness: dimensionSettings.useUpperPartStructuralSlabThickness,
        useMiddlePartStructuralSlabThickness: dimensionSettings.useMiddlePartStructuralSlabThickness,
        useLowerPartStructuralSlabThickness: dimensionSettings.useLowerPartStructuralSlabThickness,
        underStructuralSlabThickness: dimensionSettings.underStructuralSlabThickness,
        useUnderStructuralSlabThickness: dimensionSettings.useUnderStructuralSlabThickness

    })

    useEffect(() => {
        setUseOverFinishFloorThickness(value => value === useOverFinishFloor ? value : useOverFinishFloor)
    }, [useOverFinishFloor]);

    useEffect(() => {
        setDefaultStructuralSlabThickness(value => value === defaultStructuralSlab ? value : defaultStructuralSlab)
    }, [defaultStructuralSlab]);

    useEffect(() => {
        setUpperPartStructuralSlabThickness(value => value === upperPartStructuralSlab ? value : upperPartStructuralSlab)
    }, [upperPartStructuralSlab]);

    useEffect(() => {
        setUseUpperPartStructuralSlabThickness(value => value === useUpperPartStructuralSlab ? value : useUpperPartStructuralSlab)
    }, [useUpperPartStructuralSlab]);

    useEffect(() => {
        setUseLowerPartStructuralSlabThickness(value => value === useLowerPartStructuralSlab ? value : useLowerPartStructuralSlab)
    }, [useLowerPartStructuralSlab]);

    useEffect(() => {
        setUseMiddlePartStructuralSlabThickness(value => value === useMiddlePartStructuralSlab ? value : useMiddlePartStructuralSlab)
    }, [useMiddlePartStructuralSlab]);

    useEffect(() => {
        setUseUnderStructuralSlabThickness(value => value === useUnderStructuralSlab ? value : useUnderStructuralSlab)
    }, [useUnderStructuralSlab]);

    useEffect(() => {
        setFloorMeasurementVariant(value => value === measurement ? value : measurement)
    }, [measurement]);


    function handleResetClick() {
        setResetSaveSnackbar(true)
        dispatch(resetBuildingDimensionsToCompanyDefault({projectId: projectId}))
    }

    function openSaveDialog(event) {
        event.stopPropagation()
        setSaveOpen(true)
    }

    const closeSaveDialog = (event) => {
        event.stopPropagation()
        setSaveOpen(false)
    }

    function handleSaveClick() {
        setShowSaveSnackbar(true)
        setSaveOpen(false)
        console.log(settings)

        dispatch(updateCompanyDimensionSettings({
            companyId: companyId,
            unitSystem: unitSystem,
            settings: dimensionSettings
        }))
    }

    function updateDimensionStructuralSlab(setting, value) {
        value = Number(value)
        // eslint-disable-next-line default-case
        switch (setting) {
            case "defaultStructuralSlabThickness": {
                const hasDiff = value !== defaultStructuralSlabThickness
                value = Math.max(minStep * 2, value)
                if (hasDiff) {
                    setResetValues(!resetValues)
                }

                const upperPart = upperPartStructuralSlabThickness < value
                    ? upperPartStructuralSlabThickness
                    : Math.max(0, value - minStep)

                const buildingDimensions = {
                    "defaultStructuralSlabThickness": value,
                    "upperPartStructuralSlabThickness": upperPart,
                }

                updateDimensions(buildingDimensions)
                break
            }

            case "upperPartStructuralSlabThickness": {
                const hasDiff = value !== upperPartStructuralSlabThickness
                value = Math.max(minStep, value)
                if (hasDiff) {
                    setResetValues(!resetValues)
                }

                const structuralSlabThickness = value < defaultStructuralSlabThickness
                    ? defaultStructuralSlabThickness
                    : value + minStep

                const buildingDimensions = {
                    "defaultStructuralSlabThickness": structuralSlabThickness,
                    "upperPartStructuralSlabThickness": value
                }

                updateDimensions(buildingDimensions)
                break
            }
        }
    }


    function updateDimension(setting, value) {
        const buildingDimensions = {}
        buildingDimensions[setting] = value

        updateStates(setting, value)
        dispatch(updateBuildingDimensions(
            {
                projectId: projectId,
                buildingDimensions: buildingDimensions
            }))
    }

    function updateDimensions(buildingDimensions) {
        for (const [setting, value] of Object.entries(buildingDimensions)) {
            updateStates(setting, value)
        }

        dispatch(updateBuildingDimensions(
            {
                projectId: projectId,
                buildingDimensions: buildingDimensions
            }))
    }

    function updateStates(setting, value) {
        switch (setting) {
            case "useOverFinishFloorThickness":
                setUseOverFinishFloorThickness(value)
                break
            case "defaultStructuralSlabThickness":
                setDefaultStructuralSlabThickness(value)
                break
            case "upperPartStructuralSlabThickness":
                setUpperPartStructuralSlabThickness(value)
                break
            case "useUpperPartStructuralSlabThickness":
                setUseUpperPartStructuralSlabThickness(value)
                break
            case "useMiddlePartStructuralSlabThickness":
                setUseMiddlePartStructuralSlabThickness(value)
                break
            case "useLowerPartStructuralSlabThickness":
                setUseLowerPartStructuralSlabThickness(value)
                break
            case "useUnderStructuralSlabThickness":
                setUseUnderStructuralSlabThickness(value)
                break
            case "floorMeasurementVariant":
                setFloorMeasurementVariant(value)
                break
            default:
                break
        }
    }

    const isFinishFloorLevel = FloorMeasurementVariants.FINISH_FLOOR_LEVEL === floorMeasurementVariant

    const classes = useStyle();

    const options = [
        {
            option: FloorMeasurementVariants.STRUCTURAL_FLOOR_LEVEL,
            checked: !isFinishFloorLevel,
            subtitle: 'Structure Slab Level to\nStructural Slab Level',
            image: SSL,
        },
        {
            option: FloorMeasurementVariants.FINISH_FLOOR_LEVEL,
            checked: isFinishFloorLevel,
            subtitle: 'From Finished floor level to\nFinished Floor Level',
            image: FFL,
        }
    ]

    function getAttachmentLineContent() {
        const inputWidth = '8rem'
        const toolTipAttachmentLines = getToolTipTitle('Attachment Lines', 'Activate at least one attachment line');

        const onlyOneAttachmentLine = [useOverFinishFloorThickness, useUpperPartStructuralSlabThickness, useMiddlePartStructuralSlabThickness, useLowerPartStructuralSlabThickness, useUnderStructuralSlabThickness]
            .filter(value => value).length === 1

        return (
            <Fragment>
                <Typography variant={"subtitle1"}>Attachment Settings</Typography>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div style={{paddingRight: '1.6rem'}}>
                        <StoLengthInput value={defaultFinishedFloorThickness}
                                        onBlur={(value) => updateDimension("defaultFinishedFloorThickness", value)}
                                        style={{width: inputWidth}}
                                        padding={'4rem 0'}
                                        toolTip={getToolTipTitle('Finished floor thickness', 'Define the thickness of the standard finished floor')}/>
                        <StoLengthInput value={defaultStructuralSlabThickness}
                                        onBlur={(value) => updateDimensionStructuralSlab("defaultStructuralSlabThickness", value)}
                                        style={{width: inputWidth}}
                                        padding={'3rem 0'}
                                        resetValues={resetValues}
                                        toolTip={getToolTipTitle('Structural floor thickness', 'Define the thickness of the standard structural floor')}/>
                    </div>
                    <div style={{paddingTop: '2rem'}}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '20rem',
                            height: '8rem',
                            borderTop: 'solid .2rem #c6c6c6',
                            borderRight: 'solid .2rem #c6c6c6',
                            background: "repeating-linear-gradient( -45deg, #c6c6c6, #c6c6c6 .3rem, #fff .3rem, #fff 2rem)"
                        }}>
                            <Typography align={'center'} width={'100%'}>Finished Floor</Typography>
                        </div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '20rem',
                            height: '14rem',
                            borderTop: 'solid .2rem #8f8f8f',
                            borderRight: 'solid .2rem #8f8f8f',
                            borderBottom: 'solid .2rem #8f8f8f',
                            background: "repeating-linear-gradient( -45deg, #c6c6c6, #c6c6c6 .3rem, #fff .3rem, #fff 1rem)"
                        }}>
                            <Typography align={'center'} width={'100%'}>Structural Slab</Typography>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', width: '10rem'}}>
                        <div style={{borderBottom: 'solid .4rem #51ff5e'}}/>
                        <div style={{borderBottom: 'solid .4rem #f0f018', paddingTop: '9.7rem'}}/>
                        <div style={{borderBottom: 'solid .4rem #bf38ff', paddingTop: '6.7rem'}}/>
                        <div style={{borderBottom: 'solid .4rem #efb404', paddingTop: '6.7rem'}}/>
                        <div style={{borderBottom: 'solid .4rem #ff8b30', paddingTop: '4.8rem'}}/>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', paddingLeft: '1.2rem'}}>
                        <StoCheckbox style={{marginTop: '-.8rem'}}
                                     value={useOverFinishFloorThickness}
                                     onChange={value => updateDimension("useOverFinishFloorThickness", value)}
                                     disabled={useOverFinishFloorThickness && onlyOneAttachmentLine}
                                     toolTip={toolTipAttachmentLines}/>
                        <StoCheckbox style={{paddingTop: '7.8rem'}}
                                     value={useUpperPartStructuralSlabThickness}
                                     onChange={value => updateDimension("useUpperPartStructuralSlabThickness", value)}
                                     disabled={useUpperPartStructuralSlabThickness && onlyOneAttachmentLine}
                                     toolTip={toolTipAttachmentLines}/>
                        <StoCheckbox style={{paddingTop: '5rem'}}
                                     value={useMiddlePartStructuralSlabThickness}
                                     onChange={value => updateDimension("useMiddlePartStructuralSlabThickness", value)}
                                     disabled={useMiddlePartStructuralSlabThickness && onlyOneAttachmentLine}
                                     toolTip={toolTipAttachmentLines}/>
                        <StoCheckbox style={{paddingTop: '4.8rem'}}
                                     value={useLowerPartStructuralSlabThickness}
                                     onChange={value => updateDimension("useLowerPartStructuralSlabThickness", value)}
                                     disabled={useLowerPartStructuralSlabThickness && onlyOneAttachmentLine}
                                     toolTip={toolTipAttachmentLines}/>
                        <StoCheckbox style={{paddingTop: '3rem'}}
                                     value={useUnderStructuralSlabThickness}
                                     onChange={value => updateDimension("useUnderStructuralSlabThickness", value)}
                                     disabled={useUnderStructuralSlabThickness && onlyOneAttachmentLine}
                                     toolTip={toolTipAttachmentLines}/>
                    </div>
                    <div style={{paddingLeft: '1.2rem'}}>
                        <StoLengthInput value={overFinishFloorThickness}
                                        onBlur={(value) => updateDimension("overFinishFloorThickness", value)}
                                        style={{width: inputWidth}}
                                        padding={'2.6rem 0'}/>
                        <StoLengthInput value={upperPartStructuralSlabThickness}
                                        onBlur={(value) => updateDimensionStructuralSlab("upperPartStructuralSlabThickness", value)}
                                        style={{width: inputWidth}}
                                        padding={'2rem 0'}
                                        resetValues={resetValues}/>
                        <StoLengthInput value={underStructuralSlabThickness}
                                        onBlur={(value) => updateDimension("underStructuralSlabThickness", value)}
                                        style={{width: inputWidth}}
                                        padding={'6.6rem 0 4rem 0'}/>
                    </div>
                </div>
            </Fragment>
        )
    }


    return <div>
        <StoSnackbar open={showSaveSnackbar}
                     vertical={'top'} horizontal={'center'}
                     duration={3000}
                     onClose={() => setShowSaveSnackbar(false)}
                     message={"Panel settings successfully saved as company settings"}
        />
        <StoSnackbar open={showResetSnackbar}
                     vertical={'top'} horizontal={'center'}
                     duration={3000}
                     icon={ResetDoneIcon}
                     onClose={() => setResetSaveSnackbar(false)}
                     message={"Panel settings successfully reset to company settings"}
        />
        <div style={{alignItems: 'center', display: 'flex'}}>
            <Typography style={{paddingRight: '31rem'}} variant="h1">Dimensions</Typography>
            <StoIconButton
                icon={ResetIcon} tooltip={"Reset panel settings and dimensions to company standard settings"}
                onClick={() => handleResetClick()}
            />

            {isAdmin && <StoIconButton
                icon={SaveIcon}
                tooltip={"Define current panel settings and dimensions as default company settings"}
                onClick={(event) => openSaveDialog(event)}
            />}
        </div>

        <div className={classes.formular}>
            <StoLengthInput title={"Default Floor height"}
                            onBlur={(value) => updateDimension("defaultFloorHeight", value)}
                            value={defaultFloorHeight}
                            theme={'padding'}
                            padding={"0 0"}/>
            {getAttachmentLineContent()}
            <StoLengthInput title={"Ground level structural slab thickness"}
                            onBlur={(value) => updateDimension("groundStructuralSlabThickness", value)}
                            value={groundStructuralSlabThickness}
                            theme={'padding'}
                            padding={"0 0"}/>
            <StoLengthInput title={"Ground level finished floor thickness"}
                            onBlur={(value) => updateDimension("groundFinishedFloorThickness", value)}
                            value={groundFinishedFloorThickness}
                            theme={'padding'}
                            padding={"0 0"}/>
            <StoLengthInput title={"Parapet height"}
                            onBlur={(value) => updateDimension("parapetHeight", value)}
                            value={parapetHeight}
                            theme={'padding'}
                            padding={"0 0"}/>
            <StoImageOptions title={"Please select measurement system for level height"} options={options}
                             onChange={(option) => updateDimension("floorMeasurementVariant", options.findIndex(o => o.option === option))}/>

        </div>
        <StoDialog open={saveOpen} onClose={(event) => closeSaveDialog(event)}
                   title={""}
                   maxWidth={"36rem"}
                   content={
                       <div>
                           <Typography variant={"h1"} className={classes.deleteProjectTitle}>
                               {"Are you sure you want to override your company standard settings?"}
                           </Typography>
                           <Typography variant={"subtitle1"} style={{paddingTop: "2rem"}}>
                               {"This will override the company standard settings for all projects in your company."}
                           </Typography>
                       </div>
                   }
                   actions={<div className={classes.dialogActions}>
                       <StoPrimaryButton onClick={(event) => handleSaveClick(event)} title={"Save"}/>
                       <StoPrimaryButton onClick={(event) => closeSaveDialog(event)} title={"Cancel"} secondary/>
                   </div>}/>


    </div>

}

export default Dimension