import axios from "axios";
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {setElevations} from "../unity/react-unity-adapter";
import {saveAs} from 'file-saver';

export const getElevations = createAsyncThunk('elevations/get', async ({projectId}) => {
    try {
        console.log('Get all elevations: ', projectId)
        return await axios.get(`/api/projects/${projectId}/elevationPlan`)
    } catch (err) {
        return err.message
    }
})

export const downloadElevationsDXF = createAsyncThunk('projects/downloadElevationDXF', async ({ projectId }) => {
    try {
        const response = await axios.get(`/api/projects/${projectId}/elevationPlan/dxf`, {
            responseType: 'blob' // Wichtig, um die Antwort als Blob zu erhalten
        });

        // Verwendung von file-saver, um die Datei zu speichern
        // TODO: USE zip
        saveAs(response.data, 'elevations.zip');
    } catch (err) {
        console.error(err);
        return err.message;
    }
})

const elevationsSlice = createSlice({
    name: 'elevations',
    initialState: {
        elevations: [],
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getElevations.pending, (state) => {
                state.loading = true
            })
            .addCase(getElevations.fulfilled, (state, action) => {
                state.loading = false
                state.elevations = action.payload.data
                setElevations(state.elevations)
            })
            .addCase(getElevations.rejected, (state) => {
                state.loading = false
            })

    }
})

export default elevationsSlice.reducer
