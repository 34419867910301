import {makeStyles} from "@mui/styles";
import {Tooltip, Typography} from "@mui/material";
import PropTypes from "prop-types";
import {useEffect, useRef} from "react";

const useStyle = makeStyles(() => ({
    root: {
        display: "flex",
        alignItems: "center",
    },
    container: {
        position: 'relative',
    },
    checkbox: {
        height: "1.6rem",
        width: "1.6rem",
        cursor: "pointer",
        borderRadius: '.2rem',
        accentColor: '#FFD700',
    },

}));

export const CHECKBOX_STATES = {
    Checked: 'Checked',
    Indeterminate: 'Indeterminate',
    Empty: 'Empty',
};

function StoCheckbox({
                         title, value,
                         orientation = "right",
                         width = "40rem",
                         style = {},
                         disabled = false,
                         toolTip = null,
                         onChange = () => {
                         }
                     }) {
    const classes = useStyle();

    const checkboxRef = useRef();

    useEffect(() => {
        if (value === CHECKBOX_STATES.Indeterminate) {
            checkboxRef.current.checked = false;
            checkboxRef.current.indeterminate = true;
        } else if (value === CHECKBOX_STATES.Checked) {
            checkboxRef.current.checked = true;
            checkboxRef.current.indeterminate = false;
        } else if (value === CHECKBOX_STATES.Empty) {
            checkboxRef.current.checked = false;
            checkboxRef.current.indeterminate = false;
        } else if (value) {
            checkboxRef.current.checked = true;
            checkboxRef.current.indeterminate = false;
        } else {
            checkboxRef.current.checked = false;
            checkboxRef.current.indeterminate = false;
        }
    }, [value]);

    const getCheckbox = () => {
        return (
            <div className={classes.container}>
                <input className={classes.checkbox} type={"checkbox"} ref={checkboxRef}
                       onChange={event => onChange(event.target.checked)}
                       disabled={disabled}/>
            </div>)
    }

    return (
        <div className={classes.root} style={style}>
            {orientation === "right" ?
                <div className={classes.root}>
                    {title && <Typography style={{width: width}} variant={"subtitle1"}>{title}</Typography>}
                    {toolTip ? <Tooltip title={toolTip} arrow placement={'right'}>{getCheckbox()}</Tooltip> : getCheckbox()}
                </div>
                :
                <div className={classes.root}>
                    {title && <Typography style={{width: width, paddingLeft: '1.5rem'}}
                                          variant={"subtitle1"}>{title}</Typography>}
                    {toolTip ? <Tooltip title={toolTip} arrow placement={'right'}>{getCheckbox()}</Tooltip> : getCheckbox()}
                </div>

            }
        </div>)
}

StoCheckbox.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
}

export default StoCheckbox