/*
import {makeStyles} from "@mui/styles";

const useStyle = makeStyles(() => ({

}))
*/
import StoPricingMenu from "../../utils/components/StoPricingMenu";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import StoPricePerUnitInput from "../../utils/components/StoPricePerUnitInput";
import {updateLvl1GeneralConfig} from "../pricingSlice";

function PricingLevel1() {
    const unit = useSelector(state => state.unitSystem.unitSystem)
    const panelTypes = useSelector(state => state.panelTypes.panelTypes).filter((type => type.active === true))
    const generalConfigLevel1Store = useSelector(state => state.pricing.generalConfigLevel1)
    const {projectId} = useParams();
    const dispatch = useDispatch()

    const [generalConfigLevel1, setGeneralConfigLevel1] = useState(generalConfigLevel1Store)


    useEffect(() => {
        setGeneralConfigLevel1(generalConfigLevel1Store)
    }, [generalConfigLevel1Store]);

    const style = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '3.6rem'
    }
    const textStyle = {width: '22.4rem'}
    const inputStyle = {
        height: '100%',
        width: '13.2rem',
    }

    function onChangeMoulding(value) {
        const newGeneralConfigLevel1 = {...generalConfigLevel1}

        newGeneralConfigLevel1.moulding = value
        setGeneralConfigLevel1(newGeneralConfigLevel1)

        dispatch(updateLvl1GeneralConfig({projectId: projectId, generalConfigLevel1: newGeneralConfigLevel1}))
    }
/*
    function onChangeOpeningOption(value) {
        const newGeneralConfigLevel1 = JSON.parse(JSON.stringify(generalConfigLevel1))

        newGeneralConfigLevel1.opening.isPricePerArea = value === 0
        setGeneralConfigLevel1(newGeneralConfigLevel1)
        dispatch(updateLvl1GeneralConfig({projectId: projectId, generalConfigLevel1: newGeneralConfigLevel1}))
    }
*/
    function onChangeOpeningPricePerArea(value) {
        const newGeneralConfigLevel1 = JSON.parse(JSON.stringify(generalConfigLevel1))

        newGeneralConfigLevel1.opening.pricePerArea = value
        setGeneralConfigLevel1(newGeneralConfigLevel1)
        dispatch(updateLvl1GeneralConfig({projectId: projectId, generalConfigLevel1: newGeneralConfigLevel1}))
    }
/*
    function onChangeOpeningPricePerPiece(value) {
        const newGeneralConfigLevel1 = JSON.parse(JSON.stringify(generalConfigLevel1))

        newGeneralConfigLevel1.opening.pricePerPiece = value
        setGeneralConfigLevel1(newGeneralConfigLevel1)
        dispatch(updateLvl1GeneralConfig({projectId: projectId, generalConfigLevel1: newGeneralConfigLevel1}))
    }
*/
    const {opening} = generalConfigLevel1

    return (
        <div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1.6rem',
                height: '11.4rem',
                borderBottom: '.1rem solid #CCCCCC',
                marginTop: '-1.2rem',
                marginLeft: '-3.6rem',
                marginRight: '-3.6rem',
                paddingLeft: '3.6rem'
            }}>
                {/*<LabelTogglePrice title={'Opening Base Price'}
                                  option={opening?.isPricePerArea ? 0 : 1}
                                  onOptionChanged={onChangeOpeningOption}
                                  values={[opening?.pricePerArea, opening?.pricePerPiece]}
                                  onValuesChanged={[onChangeOpeningPricePerArea, onChangeOpeningPricePerPiece]}
                                  units={['perArea', 'perPiece']}/>*/}
                <StoPricePerUnitInput title={'Opening Base Price'} value={opening?.pricePerArea}
                                      unit={'perArea'}
                                      fullWidth={false}
                                      style={style}
                                      textStyle={textStyle}
                                      inputStyle={inputStyle}
                                      onBlur={onChangeOpeningPricePerArea}/>
                <StoPricePerUnitInput title={'Moulding Base Price'} value={generalConfigLevel1?.moulding}
                                      unit={'perArea'}
                                      fullWidth={false}
                                      style={style}
                                      textStyle={textStyle}
                                      inputStyle={inputStyle}
                                      onBlur={onChangeMoulding}/>
            </div>
            {
                panelTypes.map((type, i) => {

                    return <StoPricingMenu name={type.name} id={type.id} key={i} projectId={projectId}
                                           unit={unit} isAccordion={true}></StoPricingMenu>
                })}

        </div>
    )
}

export default PricingLevel1