import React from 'react';
import {makeStyles} from "@mui/styles";
import {useTranslation} from 'react-i18next';
import {Tooltip, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {DragDropContext, Draggable, Droppable} from '@hello-pangea/dnd';
import {updateCsvPanelExportSettings, updateCsvPanelExportSettingsOptimistic} from "../../settings/settingsSlice";
import {useParams} from "react-router-dom";
import {SortableHandle} from "react-sortable-hoc";
import HandleSortIcon from "../../utils/icons/handleSort.svg";
import StoCheckbox from "../../utils/components/StoCheckbox";
import {getToolTipTitle} from "../../utils/toolTipUtil";

const useStyle = makeStyles(() => ({
    levelButton: {
        width: '125px',
        height: "inherit",
        borderRadius: '2px'
    },
    spacer: {
        width: '1rem'
    },

    headerItem: {
        backgroundColor: "#F4F6F7",
        borderBottom: "solid 1px #CCCCCC",
        height: "4.8rem",
        display: "flex",
        paddingLeft: ".8rem",
        paddingRight: ".8rem",
        fontSize: "13px",
        fontWeight: "500",
        alignItems: "center",

    },
    dndItem: {
        display: "flex",
        padding: '10px',
        alignItems: "center",
        backgroundColor: '#FFFFFF'
    },
    activeArea: {
        minHeight: '50px',

    },
    inactiveArea: {
        minHeight: '50px',

    }

}))

function CsvExportSetup(props) {
    const classes = useStyle(props.theme);
    const {t} = useTranslation();
    const exportSettings = useSelector(state => state.settings.exportSettings);
    const dispatch = useDispatch();
    const {projectId} = useParams()

    const updateGrouping = (value) => {
        const settings = {...exportSettings, groupPanels: value};


        dispatch(updateCsvPanelExportSettings({projectId: projectId, settings: settings}));
    }

    const handleOnDragEnd = (result) => {
        const {source, destination} = result;
        if (!destination) return;

        let newItems = Array.from(exportSettings.csvPanelExportOptions);
        const sourceItem = {...newItems[source.index]};

        newItems.splice(source.index, 1);

        if (destination.droppableId === source.droppableId) {
            newItems.splice(destination.index, 0, sourceItem);
        } else {
            sourceItem.active = destination.droppableId === 'activeItems';
            newItems.splice(destination.index, 0, sourceItem);
        }
        // assign translated names to objects
        const namedItems = []

        newItems.forEach((item) => {
            const newItem = {...item}
            newItem.name = t('csv_' + item.id);
            namedItems.push(newItem)
        })

        const settings = {...exportSettings, csvPanelExportOptions: namedItems};

        dispatch(updateCsvPanelExportSettingsOptimistic(settings))
        dispatch(updateCsvPanelExportSettings({projectId: projectId, settings: settings}));
    };

    const activeItems = exportSettings.csvPanelExportOptions.filter(item => item.active);
    const inactiveItems = exportSettings.csvPanelExportOptions.filter(item => !item.active);
    const RowHandler = SortableHandle((props) =>
        <img alt={'icon'} src={HandleSortIcon} className={props.className}/>);


    return (
        <div style={{marginBottom: '5rem', marginRight: '5rem'}}>

            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Tooltip title={getToolTipTitle('Group similar sized panels', 'If the size of two panels is the same,' +
                    'they are summarized in one line of the CSV file. The number of these panels is displayed in the "Amount" column.')}
                         placement={'bottom-start'}
                    >
                    <Typography variant="h1">{t('csv_export_title')}</Typography>
                    <br/>
                    <StoCheckbox key={'groupPanels'} title={t('csv_export_group')}
                                 value={exportSettings.groupPanels}
                                 onChange={value => updateGrouping(value)}/>
                </Tooltip>
                <div className={classes.headerItem} style={{marginTop: '2rem'}}>
                    <Typography variant="button">{t('csv_activeItems')}</Typography>
                </div>
                <Droppable droppableId="activeItems">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className={classes.activeArea}>

                            {activeItems.map((setting) => {
                                const globalIndex = exportSettings.csvPanelExportOptions.indexOf(setting);

                                return (
                                    <Draggable key={setting.id} draggableId={setting.id} index={globalIndex}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={classes.dndItem}
                                            >
                                                <RowHandler className={classes.icon}/>
                                                <div className={classes.spacer}/>
                                                <Typography variant="button">{t('csv_' + setting.id)}</Typography>
                                            </div>
                                        )}
                                    </Draggable>
                                )
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                <div className={classes.headerItem}>
                    <Typography variant="button">{t('csv_inactiveItems')}</Typography>
                </div>
                <Droppable droppableId="inactiveItems">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className={classes.inactiveArea}>

                            {inactiveItems.map((setting) => {
                                const globalIndex = exportSettings.csvPanelExportOptions.indexOf(setting);

                                return (
                                    <Draggable key={setting.id} draggableId={setting.id} index={globalIndex}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={classes.dndItem}
                                            >
                                                <RowHandler className={classes.icon}/>
                                                <div className={classes.spacer}/>
                                                <Typography variant="button">{t('csv_' + setting.id)}</Typography>
                                            </div>
                                        )}
                                    </Draggable>
                                )
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
}

export default CsvExportSetup;