import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {setCommands} from "../unity/react-unity-adapter";

export const updateCommands = createAsyncThunk('client/update', async ({projectId, commands}) => {
    try {
        return await axios.put(`/api/projects/${projectId}/commands`, commands)
    } catch (err) {
        return err.message
    }
})

export const getCommands = createAsyncThunk('client/get', async ({projectId}) => {
    try {
        return await axios.get(`/api/projects/${projectId}/commands`)
    } catch (err) {
        return err.message
    }
})

const commandsSlice = createSlice({
    name: 'commands',
    initialState: {},
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(updateCommands.pending, (state) => {
                state.loading = true
            })
            .addCase(updateCommands.fulfilled, (state, {payload}) => {
                state.commands = payload.data
                console.log("send back", state.commands)
                setCommands(payload.data)
            })
            .addCase(updateCommands.rejected, (state) => {
                state.loading = false
            })
            .addCase(getCommands.pending, (state) => {
                state.loading = true
            })
            .addCase(getCommands.fulfilled, (state, {payload}) => {
                state.commands = payload.data
                setCommands(payload.data)
            })
            .addCase(getCommands.rejected, (state) => {
                state.loading = false
            })
    }
})

export default commandsSlice.reducer