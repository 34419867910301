import {Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import StoSidebarItem from "../scheduling/StoSidebarItem";
import {useEffect, useState} from "react";

const useStyle = makeStyles(() => ({
    header: {
        display: "flex",
        height: "4.8rem",
        alignItems: 'center',
        paddingLeft: ".8rem",
        borderBottom: 'solid .1rem #CCCCCC'
    },
}))

function PanelConfigurationSidebar({
                                       materials, selectedMaterial, setSelectedMaterial = (item) => console.log(item)
                                   }) {
    const classes = useStyle()

    const [isMatCollapsed, setIsMatCollapsed] = useState([])

    useEffect(() => {
        const isCollapsed = [...isMatCollapsed]

        materials.forEach((material) => {
            const item = isCollapsed.find(item => item.id === material.id)

            if (!item) {
                isCollapsed.push({id: material.id, isCollapsed: false})
            }
        })

        setIsMatCollapsed(isCollapsed)
        // eslint-disable-next-line
    }, [materials]);

    function toggleMaterial(material) {
        const isCollapsed = [...isMatCollapsed]

        const item = isCollapsed.find(item => item.id === material.id)
        item.isCollapsed = !item.isCollapsed

        setIsMatCollapsed(isCollapsed)
    }

    return <div>
        <Typography className={classes.header}>Materials</Typography>

        {materials.map(material => {
            const mat = {...material}
            const panelConfiguration = mat.panelConfigurations?.find(pConfig => pConfig.panelTypeId === mat.panelTypeIdLevel4)

            if (!mat) {
                return null
            }

            mat.isCollapsed = isMatCollapsed.find(item => item.id === mat.id)?.isCollapsed
            mat.children = []

            if (panelConfiguration?.componentGroupsStructural) {
                mat.children = mat.children.concat(panelConfiguration.componentGroupsStructural)
            }
            if (panelConfiguration?.componentGroupsPerformance) {
                mat.children = mat.children.concat(panelConfiguration.componentGroupsPerformance)
            }
            if (panelConfiguration?.componentGroupsWrapping) {
                mat.children = mat.children.concat(panelConfiguration.componentGroupsWrapping)
            }
            if (panelConfiguration?.componentGroupsBuildingAttachments) {
                mat.children = mat.children.concat(panelConfiguration.componentGroupsBuildingAttachments)
            }
            if (panelConfiguration?.componentGroupsDesign) {
                mat.children = mat.children.concat(panelConfiguration.componentGroupsDesign)
            }

            mat.children = mat.children.map((child, index) => {
                const item = {...child}
                item.name = (index + 1) + ' ' + item.name
                return item
            })

            return (
                    <StoSidebarItem key={mat?.id}
                                    item={mat}
                                    childAttributes={['children']}
                                    itemStyles={[{
                                        borderBottom: 'solid .1rem #F4F6F7',
                                        backgroundColor: mat.id === selectedMaterial?.id ? '#cdcdcd' : 'white'
                                    }, {
                                        borderBottom: 'solid .1rem #F4F6F7',
                                    }]}
                                    textStyles={[{}, {fontWeight: '400'}]}
                                    onClick={(item) => setSelectedMaterial(item, material)}
                                    toggleCollapse={() => toggleMaterial(mat)}
                    />)
        })}
    </div>
}

export default PanelConfigurationSidebar