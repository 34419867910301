import CollapseIcon from "../utils/icons/collapse.svg";
import PlusIcon from "../utils/icons/plus.svg";
import {Collapse, Tooltip, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useRef, useState} from "react";
import StoInput from "../utils/components/StoInput";
import {getToolTipTitle} from "../utils/toolTipUtil";

const useStyle = makeStyles(() => ({
    item: {
        height: "4.8rem",
        background: "white",

        outlineStyle: 'none',

        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",

        paddingLeft: ".4rem",
        paddingRight: ".8rem",

        "&:hover": {
            backgroundColor: "#F4F6F7",
        }
    },
    content: {
        display: "flex",
        alignItems: "center",
        gap: ".8rem",
        width: "100%",
    },
    addIconContent: {
        position: "absolute",
        left: 0,
    },
    addIcon: {
        position: "relative",
        width: "2.4rem",
        height: "2.4rem",
        right: "-3rem",
        opacity: "0",

        "&:hover": {
            opacity: "1",
            cursor: "pointer",
            backgroundColor: "#FFD700",
        }
    },
    innerContent: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
    },
    name: {
        display: "flex",
        alignItems: "center",
        userSelect: "none",
        textWrap: "nowrap",
        color: 'black'
    },
    text: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "calc(100% - 0.8rem)",
        paddingRight: ".8rem",
        gap: ".8rem",
    },
    icon: {
        width: "2.4rem",
        cursor: "pointer",
    },
    iconSmall: {
        width: "1.6rem",
    },
}))

function StoSidebarItem({
                            item,
                            hierarchy = 0,
                            childAttributes = [],
                            itemStyles = [],
                            textStyles = [],
                            toggleCollapse = () => {
                            },
                            onClick = () => {
                            },
                            onClickIcon = () => {
                            },
                            onRename = () => {
                            },
                            onDelete = () => {
                            },
                            onAddItemBefore = () => {
                            },
                            onAddItemAfter = () => {
                            },
                        }) {
    const classes = useStyle()

    const [rename, setRename] = useState(false)
    const textRef = useRef()

    const hasCollapse = item && item['isCollapsed'] !== undefined && hierarchy < childAttributes.length && item[childAttributes[hierarchy]].length > 0

    function getContent(children) {
        return hasCollapse && children.map((child, index) => {
                return <StoSidebarItem key={index}
                                       item={child}
                                       hierarchy={hierarchy + 1}
                                       onClick={onClick}
                                       childAttributes={childAttributes}
                                       itemStyles={itemStyles}
                                       textStyles={textStyles}
                                       toggleCollapse={toggleCollapse}
                                       onClickIcon={onClickIcon}
                                       onRename={onRename}
                                       onDelete={onDelete}
                                       onAddItemBefore={onAddItemBefore}
                                       onAddItemAfter={onAddItemAfter}/>
            }
        )
    }

    function onRenameItem(event) {
        setRename(false)
        if (event.target.value !== item.name) {
            onRename(item, event.target.value)
        }
    }

    function onKeyDown(event) {
        event.stopPropagation()
        if ((event.key === 'Backspace' || event.key === 'Delete') && item.delete && !rename) {
            onDelete(item)
        }
    }

    let itemStyle = {
        paddingLeft: (!hasCollapse ? hierarchy : hierarchy - 1) * 2.4 + .8 + 'rem',
        cursor: "pointer",
    }

    if (hierarchy < itemStyles.length) {
        itemStyle = {...itemStyle, ...itemStyles[hierarchy]}
    }

    let textStyle = {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}

    if (hierarchy < textStyles.length) {
        textStyle = {...textStyle, ...textStyles[hierarchy]}
    }

    function showToolTip() {
        if (textRef.current === null) {
            return true
        }

        return textRef.current?.clientWidth >= textRef.current?.scrollWidth;

    }

    function getAddIcons() {
        const showAddIconBefore = item.addIconBefore
        const showAddIconAfter = item.addIconAfter

        return (
            <div className={classes.addIconContent}>
                {showAddIconBefore &&
                    <div className={classes.addIcon} style={{top: "-1.2rem"}} onClick={() => onAddItemBefore(item)}>
                        <div style={{display: "flex", alignItems: "center", height: "100%", width: "100%"}}>
                            <img alt={'icon'} src={PlusIcon} style={{width: "100%", height: "100%"}}/>
                        </div>
                    </div>
                }
                {showAddIconAfter &&
                    <div className={classes.addIcon} style={{top: showAddIconBefore ? "1.2rem" : "3.6rem"}}
                         onClick={() => onAddItemAfter(item)}>
                        <div style={{display: "flex", alignItems: "center", height: "100%", width: "100%"}}>
                            <img alt={'icon'} src={PlusIcon} style={{width: "100%", height: "100%"}}/>
                        </div>
                    </div>
                }
            </div>)
    }

    return (
        <div>
            <div className={classes.item} style={itemStyle} onClick={(event) => onClick(item, event)} tabIndex={0}
                 onKeyDown={onKeyDown}>
                {getAddIcons()}
                <div className={classes.content}>
                    {hasCollapse && <img alt={'icon'} src={CollapseIcon} className={classes.iconSmall}
                                         onClick={(event) => {
                                             event.stopPropagation()
                                             toggleCollapse(item, event.altKey)
                                         }}
                                         style={{transform: !item.isCollapsed ? "rotate(0deg)" : "rotate(270deg)"}}/>}
                    <div className={classes.innerContent}>
                        {rename && item.rename
                            ?
                            <StoInput value={item.name} onBlur={onRenameItem} invokeNoChanges={true} autoFocus={true}/>
                            :
                            <div className={classes.text}>
                                <Tooltip title={getToolTipTitle(item.name, "")} placement={"right"} arrow={true}
                                         disableHoverListener={showToolTip()}>
                                    <Typography className={classes.name} variant={"subtitle1"} style={textStyle}
                                                onDoubleClick={() => setRename(true)} ref={textRef}>
                                        {item.name}
                                    </Typography>
                                </Tooltip>
                                {item.secondName &&
                                    <Typography className={classes.name} variant={"subtitle2"}>
                                        {item.secondName}
                                    </Typography>
                                }
                            </div>
                        }
                        {item.icon && <img alt={'icon'} src={item.icon} className={classes.icon}
                                           onClick={(event) => {
                                               event.stopPropagation()
                                               onClickIcon(item)
                                           }}/>}
                    </div>
                </div>
            </div>
            {
                hasCollapse !== undefined && hierarchy < childAttributes.length &&
                <Collapse in={!item.isCollapsed}>
                    {getContent(item[childAttributes[hierarchy]])}
                </Collapse>
            }
        </div>
    )
}

export default StoSidebarItem