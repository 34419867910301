import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {Typography} from "@mui/material";
import {useEffect, useState} from "react";
import StoTextInput from "../utils/components/StoTextInput";
import {makeStyles} from "@mui/styles";
import {updateProjectInfo} from "./projectInfoSlice";


const useStyle = makeStyles(() => ({
    formular: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '2rem',
        width: '52.2rem',
        gap: '1rem',
        "&::after": {
            height: "4rem",
            content: '""',
        }
    },
}))

function ProjectInfo(props) {
    const classes = useStyle(props.theme);

    const dispatch = useDispatch();
    const projectId = useParams();

    const projectInfo = useSelector(state => state.projectInfo.projectInfo)

    const {name, key, location, investor, architect, characteristics, notes} = projectInfo
    const {country, city, postCode, address} = location ? location : {}

    const [newName, setName] = useState("")
    const [newKey, setKey] = useState("")
    const [newCountry, setCountry] = useState("")
    const [newCity, setCity] = useState("")
    const [newPostCode, setPostCode] = useState("")
    const [newAddress, setAddress] = useState("")
    const [newInvestor, setInvestor] = useState("")
    const [newArchitect, setArchitect] = useState("")
    const [newCharacteristics, setCharacteristics] = useState("")
    const [newNotes, setNotes] = useState("")

    useEffect(() => {
        if (!projectInfo) {
            return
        }

        setName(projectInfo.name)
        setKey(projectInfo.key)
        setCountry(projectInfo.location?.country)
        setCity(projectInfo.location?.city)
        setPostCode(projectInfo.location?.postCode)
        setAddress(projectInfo.location?.address)
        setInvestor(projectInfo.investor)
        setArchitect(projectInfo.architect)
        setCharacteristics(projectInfo.characteristics)
        setNotes(projectInfo.notes)
    }, [projectInfo])

    function updateProject(newValue, oldValue) {
        if (newValue === oldValue) {
            return
        }

        const projectInfo = {
            name: newName,
            key: newKey,
            location: {
                country: newCountry,
                city: newCity,
                postCode: newPostCode,
                address: newAddress,
            },
            investor: newInvestor,
            architect: newArchitect,
            characteristics: newCharacteristics,
            notes: newNotes
        }

        dispatch(updateProjectInfo({
            projectId: projectId.projectId,
            projectInfo: projectInfo
        }))

    }

    const placeholder = "Please type here ..."

    return <div>
        <Typography variant={'h1'}>Client</Typography>
        <div className={classes.formular}>
            <StoTextInput onChange={(event) => setName(event.target.value)}
                          onBlur={(event) => updateProject(event.target.value, name)}
                          value={newName || placeholder}
                          theme={'padding'}
                          placeholder={placeholder}
                          title={'Project Name'}
                          padding={"0 0"}/>
            <StoTextInput onChange={(event) => setKey(event.target.value)}
                          onBlur={(event) => updateProject(event.target.value, key)}
                          value={newKey || ''}
                          theme={'padding'}
                          placeholder={placeholder}
                          title={'Project Number'}
                          padding={"0 0"}/>
            <Typography variant={"h4"}>Building Location</Typography>
            <StoTextInput onChange={(event) => setCountry(event.target.value)}
                          onBlur={(event) => updateProject(event.target.value, country)}
                          value={newCountry || ''}
                          theme={'padding'}
                          placeholder={placeholder}
                          title={'Country'}
                          padding={"0 0 0 0rem"}/>
            <StoTextInput onChange={(event) => setCity(event.target.value)}
                          onBlur={(event) => updateProject(event.target.value, city)}
                          value={newCity || ''}
                          theme={'padding'}
                          placeholder={placeholder}
                          title={'City'}
                          padding={"0 0 0 0rem"}/>
            <StoTextInput onChange={(event) => setPostCode(event.target.value)}
                          onBlur={(event) => updateProject(event.target.value, postCode)}
                          value={newPostCode || ''}
                          theme={'padding'}
                          placeholder={placeholder}
                          title={'Post Code'}
                          padding={"0 0 0 0rem"}/>
            <StoTextInput onChange={(event) => setAddress(event.target.value)}
                          onBlur={(event) => updateProject(event.target.value, address)}
                          value={newAddress || ''}
                          theme={'padding'}
                          placeholder={placeholder}
                          title={'Address'}
                          padding={"0 0 0 0rem"}/>
            <StoTextInput onChange={(event) => setInvestor(event.target.value)}
                          onBlur={(event) => updateProject(event.target.value, investor)}
                          value={newInvestor || ''}
                          theme={'padding'}
                          placeholder={placeholder}
                          title={'Investor / GC'}
                          padding={"0 0"}/>
            <StoTextInput onChange={(event) => setArchitect(event.target.value)}
                          onBlur={(event) => updateProject(event.target.value, architect)}
                          value={newArchitect || ''}
                          theme={'padding'}
                          placeholder={placeholder}
                          title={'Architect'}
                          padding={"0 0"}/>
            <StoTextInput onChange={(event) => setCharacteristics(event.target.value)}
                          onBlur={(event) => updateProject(event.target.value, characteristics)}
                          value={newCharacteristics || ''}
                          theme={'padding'}
                          placeholder={placeholder}
                          multiline
                          title={'Project Characteristics'}
                          padding={"0 0"}/>
            <StoTextInput onChange={(event) => setNotes(event.target.value)}
                          onBlur={(event) => updateProject(event.target.value, notes)}
                          value={newNotes || ''}
                          theme={'padding'}
                          placeholder={placeholder}
                          multiline
                          title={'Notes'}
                          padding={"0 0"}/>
        </div>
    </div>

}

export default ProjectInfo;