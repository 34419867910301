import React from 'react'
import './export.css'
import {ExportProjectData} from './ExportProjectData'
import {ExportProjectInfo} from './ExportProjectInfo'
import {ExportCustomerInfo} from './ExportCustomerInfo'
import {ExportPanelTypes} from './ExportPanelTypes'
import {ExportMaterials} from './ExportMaterials'
import {ExportLevel3CostTable} from './ExportLevel3CostTable'
import {ExportElevationPreview} from "./ExportElevationPreview";


export const ExportOverview = (props) => {

    const exportData = props.data


    const hiddenBox = {
        display: 'block',
        opacity: 0,
    }

    const project = props.project
    let image = project.blobStorageUrl + "projects/" + project.id + "/screenshots/project/overview.png"

    return (
        <div id="container">


            <div id="content">
                <div className="row">
                    <div className="col">
                        <img src={image} id="img_overview" className="copyable" draggable="true"
                             alt="PanelizationImage"/></div>
                    <div className="col">
                        <div className="text">
                            <ExportProjectData data={exportData} elevation={"building"}/>
                        </div>
                    </div>
                </div>
                <div className="spacer"></div>
                <div className="row">

                    <div className="col">
                        <div className="text">
                            <ExportProjectInfo data={exportData}/>
                        </div>
                    </div>
                    <div className="col">
                        <div className="text">
                            <ExportCustomerInfo data={exportData}/>
                        </div>
                    </div>


                </div>
                <div className="spacer"></div>
                <div className="row">
                    <div className="col">
                        <div className="text">
                            <ExportPanelTypes data={exportData.panelizationResult.panelTypes}
                                              panelTypes={exportData.panelTypes} unitSystem={exportData.unitSystem}
                                            materials={exportData.materials} pricingLevel = {exportData.settings.pricingLevel}/>
                        </div>
                    </div>
                    <div className="col">
                        <div className="text">
                            <ExportMaterials data={exportData.panelizationResult.finishes}
                                             materials={exportData.materials} unitSystem={exportData.unitSystem}/>
                        </div>
                    </div>
                </div>
                <div className="spacer"></div>
                <div className="subheader">
                    <div>Elevations</div>
                </div>
                <div className="text">
                    {
                        exportData.panelizationResult.elevationResults.map(
                            function(elevation, i) {
                                    return (
                                        <ExportElevationPreview data={exportData} project={project} elevationResult={elevation} key={i}/>
                                    )
                            }
                        )
                    }
                </div>
                <div className="subheader">Detailed Costs</div>
                <div className="text">
                    {
                        <ExportLevel3CostTable data={exportData.pricingSummary} unitSystem={exportData.unitSystem}
                                               panelizationResult={exportData.panelizationResult} pricingLevel={exportData.settings.pricingLevel}/>
                    }

                </div>
            </div>
            <div id="copied" style={hiddenBox}>Copied to Clipboard</div>
        </div>
    )
}