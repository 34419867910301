import {useSelector} from "react-redux";
import {unitSystems} from "../../unitSystem/unitSystemSlice";
import {TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";

const FractionDigits = 2

function StoPricePerUnitInput({
                                  title,
                                  value,
                                  unit,
                                  placeholder,
                                  className,
                                  onClick = () => {
                                  },
                                  onBlur = () => {
                                  },
                                  padding,
                                  disabled = false,
                                  fullWidth = true,
                                  style = {},
                                  textStyle = {},
                                  inputStyle = {},
    child
                              }) {

    const unitSystem = useSelector(state => state.unitSystem.unitSystem)

    let unitString;
    let unitFactor = 1
    let baseUnit = unit
    if (unit && unit.includes("Area"))
        baseUnit = "perArea"

    switch (baseUnit) {
        case 'perPiece':
            unitString = " $ / piece"
            break
        case "panelPercent":
            unitString = " %"
            break
        case "percent":
            unitString = " %"
            break
        case "perPanel":
            unitString = " $ / panel"
            break
        case "perArea":
            if (unitSystems.METRICAL === unitSystem) {
                unitString = " $ / m²"
            } else {
                unitString = " $ / ft²"
                unitFactor = 10.7639
            }
            break
        default:
            if (unitSystems.METRICAL === unitSystem) {
                unitString = " $ / m"
            } else {
                unitString = " $ / ft"
                unitFactor = 3.28084
            }
    }
    const actualUnitString = unitString
    const actualUnitFactor = unitFactor

    const [internalValue, setInternalValue] = useState(getValue(value))

    useEffect(() => {
        setInternalValue(getValue(value))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);


    function getValue(v) {
        v /= actualUnitFactor
        return parseFloat(v).toFixed(FractionDigits) + actualUnitString
    }


    function onChangeValue(changedValue) {
        setInternalValue(changedValue)
    }

    function onBlurValue(blurredValue) {
        if (blurredValue.length === 0) {
            setInternalValue(getValue(value))
            return
        }

        blurredValue = blurredValue.replace(/\$.*$/g, "")
        blurredValue = blurredValue.replace(",", ".")
        blurredValue = blurredValue.replace(" ", "")
        blurredValue *= actualUnitFactor

        if (isNaN(blurredValue)
            || blurredValue === parseFloat(value).toFixed(FractionDigits)) {
            setInternalValue(getValue(value))
            return
        }

        onBlur(blurredValue)
        setInternalValue(getValue(blurredValue))
    }

    let generalStyle = {padding: padding || "2.4rem 0"}
    generalStyle = {...style, generalStyle}

    return (
        <div style={generalStyle}>
            <Typography variant={"subtitle1"} style={textStyle}>{title}</Typography>

            <TextField fullWidth={fullWidth}
                       className={className}
                       disabled={disabled}
                       style={inputStyle}
                       size={"small"}
                       variant="outlined"
                       value={internalValue}
                       inputProps={{
                           style: {
                               height: '100%',
                           },
                       }}
                       onClick={onClick}
                       onChange={event => onChangeValue(event.target.value)}
                       onBlur={event => onBlurValue(event.target.value)}
                       placeholder={placeholder}
            />
            {child ? child : null}
        </div>
    );
}

export default StoPricePerUnitInput

