import StoPricingCategory from "../../utils/components/StoPricingCategory";
import {makeStyles} from "@mui/styles";
import React, {Fragment, useEffect, useRef, useState} from "react";
import tooltips from "../../utils/tooltips/tooltips_level3.json";
import {Tooltip} from "@mui/material";
import infoIcon from "../../utils/icons/info.svg"
import {getToolTipTitle} from "../../utils/toolTipUtil";
import {PRICING_LEVEL} from "../pricingSlice";
import StoPricingItem from "../../utils/components/StoPricingItem";

const useStyle = makeStyles(() => ({
    innerBox: {
        display: 'flex',
        alignItems: 'center',
        height: '55px',
        paddingLeft: '1.5em',
    },
    pricingCategoryFont: {
        fontFamily: 'FrutigerNext-Bd',
        fontSize: '16px'
    },
    pricingItemFont: {
        fontSize: '16px',
        fontFamily: 'FrutigerNext',
    },
    placeHolder: {
        width: '14rem',
        height: '1rem',
    },
    initialPlaceholder: {
        width: '30.5rem',
        height: '1rem',
        paddingLeft: '0',
    },
    level3Header: {
        backgroundColor: '#ffffff',

        fontSize: '16px',
        fontFamily: 'FrutigerNext',
    },
    level3GroupHeader: {
        margin: '2rem 0 2rem 1rem',
        fontSize: '20px',
        fontFamily: 'FrutigerNext-Bd',
        display: 'flex',
        alignItems: 'center',
    },
    level3FloatingHeader: {
        backgroundColor: '#ffffff',
        position: 'fixed',
        top: '100px',
        fontSize: '16px',
        fontFamily: 'FrutigerNext',
        zIndex: '1000',
        display: 'none',
    },
    tooltipIcon: {
        marginLeft: '0.5rem',
    },
    superHeader: {
        marginLeft: '2rem',
        color: '#767676',
    },
    title: {
        marginLeft: '2rem',
        fontSize: '24px',
        fontFamily: 'FrutigerNext-Bd',
    }
}));

function PricingLevel2_3({scrollToItem, pricingLevel, pricingCategories, ...props}) {
    const classes = useStyle({...props.theme});
    const refsArray = useRef(pricingCategories?.map(() => React.createRef()));
    const scrollContainerRef = useRef(null);
    const [floatingHeaderDisplay, setFloatingHeaderDisplay] = useState("none");
    const [forceUpdate, setForceUpdate] = useState(0);

    useEffect(() => {
        refsArray.current = pricingCategories?.map((_, index) => refsArray.current[index] || React.createRef());
    }, [pricingLevel, pricingCategories]);


    useEffect(() => {
        setForceUpdate(forceUpdate + 1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pricingLevel]);

    useEffect(() => {
        if (scrollToItem === null) {
            return
        }

        let index = Math.max(0, scrollToItem - 1)
        index = Math.min(index, refsArray.current.length - 1)
                
        //if available, scroll to the element previous to the selected one, as the selected one is hidden by the header
        refsArray.current[index]?.current?.scrollIntoView({behavior: 'smooth'});
    }, [scrollToItem]);

    useEffect(() => {
        // Event-Listener für das Scrollen im Container hinzufügen, wenn scrollContainerRef existiert

        if (scrollContainerRef.current) {
            const parentNode = scrollContainerRef.current.parentNode.parentNode;
            const handleScroll = () => {

                const scrollPosition = parentNode.scrollTop;

                if (scrollPosition > 130) {
                    setFloatingHeaderDisplay("block");
                } else {
                    setFloatingHeaderDisplay("none");
                }
            };

            parentNode.addEventListener("scroll", handleScroll);

            return () => {
                // Event-Listener entfernen, um Speicherleck zu vermeiden
                parentNode.removeEventListener("scroll", handleScroll);
            };
        }
    }, []);

    const specificTooltip = tooltips.find((tooltip) => tooltip.id === "specificData");

    return (
        <div ref={scrollContainerRef} key={forceUpdate}>
            <div className={classes.level3Header}>
                <div className={classes.superHeader}>Pricing</div>
                <div className={classes.title}>{pricingLevel === PRICING_LEVEL.LEVEL_2 ? "Level 2" : "Level 3"}</div>

                {
                    pricingLevel === PRICING_LEVEL.LEVEL_3 &&
                    <div ref={refsArray && refsArray.current && refsArray.current.length ? refsArray.current[0] : null}>
                        <StoPricingItem category={pricingCategories ? pricingCategories[0]?.pricingItems[0] : null}
                                        backgroundColor={'#ffffff'} hideLabor={true}/>
                    </div>
                }

                <div className={classes.innerBox}>
                    <div className={classes.initialPlaceholder}>Name</div>
                    {pricingLevel === PRICING_LEVEL.LEVEL_3 ? <div className={classes.placeHolder}>Material</div> : <div className={classes.placeHolder}>Cost</div>}
                    {pricingLevel === PRICING_LEVEL.LEVEL_3 &&
                    <div className={classes.placeHolder}>Labor</div>}
                    <div className={classes.placeHolder}>Return Edge</div>
                    <div className={classes.placeHolder}>Return Opening</div>
                </div>
            </div>
            <div className={classes.level3FloatingHeader} style={{display: floatingHeaderDisplay}}>
                <div className={classes.innerBox}>
                    <div className={classes.initialPlaceholder}>Name</div>
                    <div className={classes.placeHolder}>Cost</div>
                    {pricingLevel === PRICING_LEVEL.LEVEL_3 &&
                        <div className={classes.placeHolder}>Labor</div>}
                    <div className={classes.placeHolder}>Return Edge</div>
                    <div className={classes.placeHolder}>Return Opening</div>

                </div>
            </div>

            {

                pricingCategories?.map((cat, i) => {
                    if ((pricingLevel === PRICING_LEVEL.LEVEL_3 && i === 0)) {
                        return null
                    }

                    return (<div ref={refsArray.current[i]} key={cat.id}>
                        {cat.header &&
                            <div className={classes.level3GroupHeader}>
                                {cat.header}
                                {cat.header === "Specific Data:" &&
                                    <Fragment>
                                        <Tooltip title={getToolTipTitle("Specific Data", specificTooltip.text)}
                                                 arrow={true} placement={"bottom-start"}>
                                            <img src={infoIcon}
                                                 className={classes.tooltipIcon}
                                                 alt={"Specific Data"}/>
                                        </Tooltip>
                                    </Fragment>}
                            </div>}
                        <StoPricingCategory category={cat} isItem={false} tooltips={tooltips}/>
                    </div>)
                })}
        </div>
    )
}

export default PricingLevel2_3