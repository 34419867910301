import StoDropdown from "./StoDropdown";
import {Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";


const useStyle = makeStyles(() => ({
    labelDropdown: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '3.2rem',
    },
}));


function StoLabelDropdown({label='Label Dropdown', value=1, options=[1, 2, 3],
                              labelStyle = {}, dropdownStyle={}, onChange = (value) => console.log(value),
                              disabled = false,
                              toolTip = null}) {
    const classes = useStyle();

    const typographyStyle = {
        flexShrink: '0',
        ...labelStyle
    }

    return (
        <div className={classes.labelDropdown}>
            <Typography variant={'subtitle1'} style={typographyStyle}>{label}</Typography>
            <StoDropdown dropdownStyle={dropdownStyle} initialValue={value} options={options} onChange={onChange} disabled={disabled} toolTip={toolTip}/>
        </div>
    )
}

export default StoLabelDropdown