import axios from "axios";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {setBuildingDimensionsUnity, setSettingsUnity} from "../unity/react-unity-adapter";

export const getSettings = createAsyncThunk('settings/get', async ({projectId}) => {
    try {
        console.log('Get settings: ', projectId)
        const response = await axios.get(`/api/projects/${projectId}/settings`)
        setSettingsUnity(response.data)
        return response
    } catch (err) {
        return err.message
    }
})

export const getCsvPanelExportSettings = createAsyncThunk('csvExportSettings/get', async ({projectId}) => {
    try {
        return await axios.get(`/api/projects/${projectId}/settings/panelExportCsvSettings`)
    } catch (err) {
        return err.message
    }
})

export const updateCsvPanelExportSettings = createAsyncThunk('csvExportSettings/update', async ({
                                                                                                    projectId,
                                                                                                    settings
                                                                                                }) => {
    try {
        return await axios.put(`/api/projects/${projectId}/settings/panelExportCsvSettings`, settings)
    } catch (err) {
        return err.message
    }

})

export const updateCsvPanelExportSettingsOptimistic = createAsyncThunk(
    'settings/updateCsvPanelExportSettingsOptimistic',
    async (data, {rejectWithValue}) => {
        try {
            // Hier würde normalerweise die API-Anfrage stehen
            return data;  // Simulieren einer erfolgreichen Antwort
        } catch (error) {
            return rejectWithValue('Failed to update');
        }
    }
);



export const updateCompanyDimensionSettings = createAsyncThunk('panelSettings/update', async ({
                                                                                              companyId, unitSystem,settings
                                                                                          }) => {
    try {
        console.log('Update panel settings for company: ', companyId)
        return await axios.put(`/api/company/settings/dimension/`, settings
            , {params: {companyId: companyId, unitSystem: unitSystem}})
    } catch (err) {
        return err.message
    }
})

export const updateCompanyPanelSettings = createAsyncThunk('panelSettings/update', async ({
                                                                                              companyId, unitSystem,settings
}) => {
    try {
        console.log('Update panel settings for company: ', companyId)
        return await axios.put(`/api/company/settings/panel/`, settings
            , {params: {companyId: companyId, unitSystem: unitSystem}})
    } catch (err) {
        return err.message
    }
})

export const resetPanelSettingsToCompanyDefault =
    createAsyncThunk('panelSettings/reset', async ({projectId}) => {
        try {
            console.log('Reset panel settings')
            return await axios.get(`/api/projects/${projectId}/panel/reset`)
        } catch (err) {
            return err.message
        }
    })

export const resetBuildingDimensionsToCompanyDefault =
    createAsyncThunk('buildingDimensions/reset', async ({projectId}) => {
        try {
            console.log('Reset panel settings')
            return await axios.get(`/api/projects/${projectId}/dimension/reset`)
        } catch (err) {
            return err.message
        }
    })

export const updateSettings =
    createAsyncThunk('settings/update', async ({projectId, settings}) => {
        try {
            console.log('Update settings: ', settings)
            return await axios.put(`/api/projects/${projectId}/settings`, settings)
        } catch (err) {
            return err.message
        }
    })

export const updatePricingLevel = createAsyncThunk('settings/updatePricingLevel', async ({projectId, pricingLevel}) => {
    try {
        console.log('Update pricing level: ', pricingLevel)
        return await axios.put(`/api/projects/${projectId}/settings/pricingLevel/${pricingLevel}`)
    } catch (err) {
        return err.message
    }
})

export const updateBuildingDimensions = createAsyncThunk('buildingDimensions/update', async ({
                                                                                                 projectId,
                                                                                                 buildingDimensions
                                                                                             }) => {
    try {
        console.log('Update building dimensions: ', buildingDimensions)
        const response = await axios.put(`/api/projects/${projectId}/settings/buildingDimensions`, buildingDimensions)
        setBuildingDimensionsUnity(response.data)
        return response
    } catch (err) {
        return err.message
    }
})

export const resetPanelSettings = createAsyncThunk('settings/resetPanelSettings', async ({projectId}) => {
    try {
        console.log('Reset panel settings: ', projectId)
        const response = await axios.put(`/api/projects/${projectId}/settings/resetPanelSettings`)
        setSettingsUnity(response.data)
        return response
    } catch (err) {
        return err.message
    }
})

export const FloorMeasurementVariants = {
    FINISH_FLOOR_LEVEL: 'FINISH_FLOOR_LEVEL',
    STRUCTURAL_FLOOR_LEVEL: 'STRUCTURAL_FLOOR_LEVEL',
}

const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
        settings: {},
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder

            .addCase(updateCsvPanelExportSettingsOptimistic.pending, (state, action) => {
                state.lastValidState = {...state.settings};
                state.exportSettings = action.meta.arg;
            })

            .addCase(updateCsvPanelExportSettingsOptimistic.fulfilled, (state, action) => {
                state.exportSettings = action.payload;
            })


            .addCase(updateCsvPanelExportSettingsOptimistic.rejected, (state) => {
                state.exportSettings = state.lastValidState;
            })

            .addCase(getCsvPanelExportSettings.pending, (state) => {
                state.loading = true
            })
            .addCase(getCsvPanelExportSettings.fulfilled, (state, {payload}) => {
                state.exportSettings = payload.data
                state.loading = false
            })
            .addCase(getCsvPanelExportSettings.rejected, (state) => {
                state.loading = false
            })
            .addCase(updateCsvPanelExportSettings.pending, (state) => {
                state.loading = true
            })
            .addCase(updateCsvPanelExportSettings.fulfilled, (state) => {
                //state.exportSettings = payload.data
                state.exportSettings.groupPanels = !state.exportSettings.groupPanels
                state.loading = false
            })
            .addCase(updateCsvPanelExportSettings.rejected, (state) => {
                state.loading = false
            })
            .addCase(getSettings.pending, (state) => {
                state.loading = true
            })
            .addCase(getSettings.fulfilled, (state, {payload}) => {
                const settings = payload.data
                if (!settings) {
                    return
                }

                state.settings = settings
                state.settings.reset = false
                state.loading = false
                setSettingsUnity(state.settings)
            })
            .addCase(getSettings.rejected, (state) => {
                state.loading = false
            })
            .addCase(updateSettings.pending, (state) => {
                state.loading = true
            })
            .addCase(updateSettings.fulfilled, (state, {payload}) => {
                const settings = payload.data

                if (settings) {
                    state.settings = settings
                    state.settings.reset = false
                    setSettingsUnity(settings)
                }
                state.loading = false
            })
            .addCase(updateSettings.rejected, (state) => {
                state.loading = false
            })
            .addCase(resetPanelSettings.pending, (state) => {
                state.loading = true
            })
            .addCase(resetPanelSettings.fulfilled, (state, {payload}) => {
                const settings = payload.data
                if (settings) {
                    state.settings = settings
                    state.settings.reset = true
                }
                state.loading = false
            })
            .addCase(resetPanelSettings.rejected, (state) => {
                state.loading = false
            })

            .addCase(updateBuildingDimensions.pending, (state) => {
                state.loading = true
            })
            .addCase(updateBuildingDimensions.fulfilled, (state, {payload}) => {
                const buildingDimensions = payload.data
                const {settings} = state
                if (settings) {
                    settings.buildingDimensions = buildingDimensions
                    state.settings = settings
                    state.settings.reset = false
                }
                state.loading = false

            })
            .addCase(updateBuildingDimensions.rejected, (state) => {
                state.loading = false
            })
            .addCase(updatePricingLevel.pending, (state) => {
                state.loading = true
            })
            .addCase(updatePricingLevel.fulfilled, (state, {payload}) => {
                const pricingLevel = payload.data
                console.log("Update pricing level to " + pricingLevel)
                const {settings} = state
                if (settings) {
                    settings.pricingLevel = pricingLevel
                    state.settings = settings
                    state.settings.reset = false
                    setSettingsUnity(settings)
                }
                state.loading = false
            })
            .addCase(updatePricingLevel.rejected, (state) => {
                state.loading = false
            })


            .addCase(resetPanelSettingsToCompanyDefault.rejected, (state) => {
                state.loading = false
                state.settings.reset = false

            })

            .addCase(resetPanelSettingsToCompanyDefault.pending, (state) => {
                state.loading = true
                state.settings.reset = false
            })

            .addCase(resetPanelSettingsToCompanyDefault.fulfilled, (state, {payload}) => {
                state.settings = payload.data
                state.settings.reset = true
                state.loading = false
            })

            .addCase(resetBuildingDimensionsToCompanyDefault.rejected, (state) => {
                state.loading = false
                //state.settings.buildingDimensions.reset = false

            })

            .addCase(resetBuildingDimensionsToCompanyDefault.pending, (state) => {
                state.loading = true
                //state.settings.reset = false
            })

            .addCase(resetBuildingDimensionsToCompanyDefault.fulfilled, (state, {payload}) => {
                state.settings.buildingDimensions = payload.data
                console.log(payload)
                //state.settings.reset = true
                state.loading = false
            })

    }
})

export default settingsSlice.reducer