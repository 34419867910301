import {makeStyles} from "@mui/styles";
import React, {useEffect, useRef, useState} from "react";
import {Collapse, Tooltip, Typography} from "@mui/material";
import CollapseIcon from "../../utils/icons/collapse.svg";
import StoLabelDropdown from "../../utils/components/StoLabelDropdown";

import './pricingLevel4.css'
import {getSrcByComponentGroup} from "./panelConfigurationImage";
import {getToolTipTitle} from "../../utils/toolTipUtil";
import {DateTime} from "luxon";
import {useSelector} from "react-redux";
import {InfoOutlined} from "@mui/icons-material";

const layerColumn = '6.4rem'
const nameColumn = '26.4rem'

const useStyle = makeStyles(() => ({
    header: {
        position: 'fixed',
        width: 'calc(100% - 69.2rem)',
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        height: '4.8rem',
        background: '#f2f2f2',
        borderBottom: '0.25px solid #7F7F7F',
        paddingLeft: '3.2rem',
    },
    content: {
        background: '#f2f2f2',
        boxSizing: 'border-box',
        paddingBottom: '1.6rem',
        marginBottom: '-3.2rem',
    },
    materialContent: {
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        flexDirection: 'row',
        background: 'white',
        marginLeft: '3.2rem',
        marginRight: '3.2rem',
        marginBottom: '1.6rem',
        paddingRight: '3.2rem',
        paddingTop: '.8rem',
        paddingBottom: '.8rem',
    },
    collapse: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: '.8rem',
        width: '6.4rem',
        flexDirection: 'row',
    },
    icon: {
        width: '2.4rem',
        height: '2.4rem',
    },
    materialDropdowns: {
        display: 'flex',
        flexDirection: 'column',
        gap: '.8rem',
        paddingTop: '.8rem',
        width: '100%'
    },
    panelConstructionImage: {
        paddingRight: '3.2rem',
        width: '19.2rem',
        height: '25.4rem'
    }
}))


function PricingLevel4({
                           currentPanelConfiguration,
                           scrollToItem = {},
                           showHeader = true,
                           updatePanelConfiguration = (componentGroupDTO) => console.log(componentGroupDTO)
                       }) {
    const classes = useStyle();

    const scrollContainerRef = useRef(null);

    const [scrollRefs, setScrollRefs] = useState([])

    const [componentGroups, setComponentGroups] = useState([])

    const {newestPanelConfigurationTimestamp} = useSelector(state => state.pricing)

    useEffect(() => {
        if (!currentPanelConfiguration) {
            setScrollRefs([])
            setComponentGroups([])
            return
        }

        let newComponentGroups = []
        let startIndex = 0

        if (currentPanelConfiguration?.componentGroupsStructural) {
            const groups = currentPanelConfiguration.componentGroupsStructural.map((componentGroup) => {
                const newComponentGroup = {
                    ...componentGroup,
                    parent: 'structural'
                };
                return newComponentGroup;
            });
            newComponentGroups = newComponentGroups.concat(groups)
            newComponentGroups = newComponentGroups.map((component =>
                    component.index !== undefined ? component : {...component, index: startIndex++}
            ))
        }
        if (currentPanelConfiguration?.componentGroupsPerformance) {
            const groups = currentPanelConfiguration.componentGroupsPerformance.map((componentGroup) => {
                const newComponentGroup = {
                    ...componentGroup,
                    parent: 'performance'
                };
                return newComponentGroup;
            });
            newComponentGroups = newComponentGroups.concat(groups)
            newComponentGroups = newComponentGroups.map((component =>
                    component.index !== undefined ? component : {...component, index: startIndex++}
            ))
        }

        if (currentPanelConfiguration?.componentGroupsWrapping) {
            newComponentGroups = newComponentGroups.concat(currentPanelConfiguration.componentGroupsWrapping)
            newComponentGroups = newComponentGroups.map((component =>
                    component.index !== undefined ? component : {...component, index: startIndex++}
            ))
        }
        if (currentPanelConfiguration?.componentGroupsBuildingAttachments) {
            newComponentGroups = newComponentGroups.concat(currentPanelConfiguration.componentGroupsBuildingAttachments)
            newComponentGroups = newComponentGroups.map((component =>
                    component.index !== undefined ? component : {...component, index: startIndex++}
            ))
        }
        if (currentPanelConfiguration?.componentGroupsDesign && !showHeader) {
            const groups = currentPanelConfiguration.componentGroupsDesign.map((componentGroup) => {
                const newComponentGroup = {
                    ...componentGroup,
                    parent: 'design'
                };
                return newComponentGroup;
            });
            newComponentGroups = newComponentGroups.concat(groups)
            newComponentGroups = newComponentGroups.map((component =>
                    component.index !== undefined ? component : {...component, index: startIndex++}
            ))
        } else {
            startIndex += currentPanelConfiguration?.componentGroupsDesign?.length
        }

        const newScrollRefs = newComponentGroups.map((componentGroup) => {
            return {
                componentGroup: componentGroup,
                ref: React.createRef(),
                isCollapsed: false,
                index: componentGroup.index
            }
        })
        setScrollRefs(newScrollRefs)
        setComponentGroups(newComponentGroups)
    }, [currentPanelConfiguration, showHeader]);

    useEffect(() => {
        const scrollRef = scrollRefs?.find(scrollRef => scrollRef.componentGroup?.name === scrollToItem?.componentGroup?.name)
        const index = scrollRefs?.findIndex(scrollRef => scrollRef.componentGroup?.name === scrollToItem?.componentGroup?.name)

        if (!scrollRef) {
            return
        }

        if (index === 0) {
            scrollContainerRef?.current?.scrollIntoView({behavior: 'smooth', block: 'start'})
        } else {
            scrollRef.ref.current?.scrollIntoView({behavior: 'smooth', block: 'start'})
        }
        // eslint-disable-next-line
    }, [scrollToItem])

    function setCollapse(componentGroup) {
        const newScrollRefs = scrollRefs.map(scrollRef => {
            if (scrollRef.componentGroup === componentGroup) {
                return {
                    ...scrollRef,
                    isCollapsed: !scrollRef.isCollapsed
                }
            }
            return scrollRef
        })
        setScrollRefs(newScrollRefs)
    }

    function getContent() {
        return componentGroups && componentGroups.length > 0
            ? componentGroups.map((componentGroup) => {
                const scrollRef = scrollRefs?.find(scrollRef => scrollRef.componentGroup === componentGroup)
                const isCollapsed = scrollRef ? scrollRef.isCollapsed : false
                const index = scrollRef ? scrollRef.index : 0

                return (
                    <div key={index} ref={scrollRef?.ref}>
                        <div style={{display: 'flex', alignItems: 'center', height: '4rem', paddingLeft: '3.2rem'}}>
                            <div className={classes.collapse}>
                                <img className={classes.icon} src={CollapseIcon} alt={'icon'}
                                     onClick={() => {
                                         setCollapse(componentGroup)
                                     }}
                                     style={{transform: !isCollapsed ? "rotate(0deg)" : "rotate(270deg)"}}/>
                                <Typography variant={'subtitle1'}>{index + 1}</Typography>
                            </div>
                            <Typography variant={'subtitle1'}
                                        style={{width: nameColumn}}>{componentGroup.name}</Typography>
                        </div>
                        <Collapse in={!isCollapsed}>
                            {getComponentGroupContent(componentGroup)}
                        </Collapse>
                    </div>
                )
            }) : <div style={{display: 'flex', alignItems: 'center', height: '20rem', justifyContent: 'center'}}>
                <Typography>No paneltype is selected or no data is available.</Typography>
            </div>
    }

    function getComponentGroupContent(componentGroup) {
        return (
            <div className={classes.materialContent} style={{paddingLeft: layerColumn}}>
                <div className={classes.materialDropdowns}>
                    {getComponentContent(componentGroup)}
                </div>
                <img className={classes.panelConstructionImage} src={getSrcByComponentGroup(componentGroup)}
                     alt={'panelConstruction'}/>
            </div>
        )
    }

    function getComponentContent(componentGroup) {
        const components = componentGroup.components

        return components.map((component, index) => {
            if (!component || !component.pricingMaterials) {
                return null
            }

            const materialId = component?.pricingMaterialId
            let value = null

            if (materialId) {
                value = component.pricingMaterials.find(pM => pM.id === materialId)?.name
            }
            if (!value) {
                if (componentGroup.parent === 'design') {
                    value = ''
                } else {
                    value = component.pricingMaterials.length > 0 ? component?.pricingMaterials[0].name : ''
                }
            }
            let options = component.pricingMaterials.map(pricingMaterial => pricingMaterial.name)

            //add empty slot to beginning of options
            if (componentGroup.parent === 'design' && options.length > 0) {
                options.unshift('')
            }

            function handleComponentChanged(material, componentName) {
                const value = component.pricingMaterials.find(pM => pM.name === material)

                updatePanelConfiguration({
                    name: componentGroup.name,
                    components: [{
                        name: value ? value.name : componentName,
                        pricingMaterialId: value ? value.id : null,
                    }]
                })
            }

            const disabled = options.length <= 1
            const toolTipText = disabled ? options.length === 0 ? 'No material available.' : 'Only one material available.' : null

            if (options.length === 0) {
                options = ['No material available']
                value = 'No material available'
            }

            return (<StoLabelDropdown key={index}
                                      labelStyle={{width: nameColumn}}
                                      label={component.name}
                                      value={value}
                                      options={options}
                                      disabled={disabled}
                                      toolTip={disabled ? getToolTipTitle('Info', toolTipText) : null}
                                      onChange={(value) => handleComponentChanged(value, component?.name)}/>)
        })
    }

    const timeStamp = currentPanelConfiguration?.timestamp


    const {year, month, day} = timeStamp ? DateTime.fromJSDate(new Date(timeStamp)) : {year: 0, month: 0, day: 0}
    const {
        year: newYear,
        month: newMonth,
        day: newDay
    } = newestPanelConfigurationTimestamp ? DateTime.fromJSDate(new Date(newestPanelConfigurationTimestamp)) : {
        year: 0,
        month: 0,
        day: 0
    }

    const isOutdated = year !== newYear || month !== newMonth || day !== newDay

    return (
        <div style={{width: '100%', height: '100%'}} ref={scrollContainerRef}>
            {showHeader &&
                <div className={classes.header}>
                    <Typography variant={'bodyText'} style={{width: layerColumn}}>Layer</Typography>
                    <Typography variant={'bodyText'} style={{width: nameColumn}}>Name</Typography>
                    <Typography variant={'bodyText'}
                                style={{width: isOutdated ? 'calc(100% - 45.4rem)' : 'calc(100% - 41.6rem)'}}>Material</Typography>
                    {isOutdated &&
                        <Tooltip placement={'bottom'} title={getToolTipTitle('Info', 'There is current data from ' +
                            newMonth + '-' + newDay + '-' + newYear + '. You can use these by updating')}>
                            <InfoOutlined style={{paddingRight: '.8rem', width: '2rem', height: '2rem', color: 'red'}}/>
                        </Tooltip>
                    }
                    {timeStamp &&
                        <Typography variant={'bodyText'}>{month + "-" + day + "-" + year}</Typography>
                    }
                </div>
            }
            <div className={classes.content} style={{paddingTop: showHeader ? '4.8rem' : '0.8rem'}}>
                {getContent()}
            </div>
        </div>
    )
}

export default PricingLevel4